const HandsonsData_Documents = {}

HandsonsData_Documents.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Control_disabled: true,
  Download_disabled: true,
  Button_hidden: false,
  Details_hidden: true,
  Admin_hidden: true,
  System_hidden: true,

  Btn_hidden_Old_Load: true,
  Btn_hidden_Save: true,
  Btn_hidden_Apply_Request: true,
  Btn_hidden_Apply_Cancel: true,
  Btn_hidden_Apply_Correct: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval_Cancel: true,
  Btn_hidden_Approval_Request: true,
  Btn_hidden_Approval_DounLoad: true,
  Btn_hidden_LogCSV_DownLoad: true,

  Btn2_hidden_Save: false,
  Btn2_hidden_Apply_Request: false,
  Btn2_hidden_Apply_Cancel: false,
  Btn2_hidden_Apply_Correct: false,
  Btn2_hidden_Remand: false,
  Btn2_hidden_Approval_Cancel: false,
  Btn2_hidden_Approval_Request: false,
  Btn2_hidden_Approval_DounLoad: false,

}


// 2021.05.14 K.Ueoku    一部列名変更
// 2021.05.27 H.Nakazato 列追加 Wafer、CP
// 2021.06.03 K.Ueoku    列追加 Other_1 ～15 の UL、DL 
HandsonsData_Documents.colHeaders_Name = [
  '削除',
  // 'partition_key', 
  // 'create_time', 
  // 'mode', 
  // 'Request_No', 
  // 'Project_No' ,
  'Unique No', 
  'Control No',
  'Status', 
  'Disti#', // 'Disti ID' ,
  '依頼', // '依頼分類', 
  'Date', // 'Request Date *' ,
  'REQ#', // 'Request Format#' ,
  'PJ#',

  'CQS#' ,
  'Cust. ID', // 'Customer ID' ,
  'Cust. Gp.', // 'Customer Group' ,
  'Customer（表紙宛先）' ,
  'ｸﾞﾙｰﾌﾟ会社母体名/ Div./ End Customer', // '(ｸﾞﾙｰﾌﾟ会社名母体名) Division or End Customer' ,
  'Disti' ,
  'PIC', // 'Disti PIC' ,
  'Disti Mail' ,
  'SALES', // 'WECJ Sales担当' ,
  'JQ', // 'JQ 担当' ,
  'Product Type' ,
  'Version' ,
  'P/N' ,
  // 'Product List' ,
  // 'Product List WECJ準備完了日' ,
  // 'Product List 代理店DL日' ,
  'Customer P/N' ,
  'Customer DS # / Application Remarks' ,
  'Package' ,
  'Product No', // 'Product name' ,
  'Process', // 'Die Version' ,
  'Appli Code', // 'Application Code' ,
  'Appli-Cat.', // 'Application Category' ,
  'Appli Cat-1', // 'Application Category 1' ,
  'Appli Cat-2', // 'Application Category 2' ,
  'HQ Req Date', // 'HQ Request Date' ,
  'CQS Closed', // 'HQ CQS Closed Date' ,
  'CQS TAT', // 'HQ CQS TAT' ,
  'CQS Final Receipt', // 'HQ Actual Receipt Date',
  'CQS Final TAT', // 'HQ Request Actual Closed TAT',
  'Due Date' ,
  'Issued Final' ,
  'ALL TAT(CQS有) WECJ&WEC休日' ,
  'ALL TAT(CQS無) WECJ休日' ,
  'ALL TAT' ,
  'WECJ TAT' ,
  'Delay from CRD' ,
  '表紙/報告書返却' ,
  '表紙' ,
  'UL', // '表紙 WECJ準備完了日' ,
  'DL', // '表紙 代理店DL日' ,
  'Data Sheet' ,
  'UL', // 'Data Sheet WECJ準備完了日' ,
  'DL', // 'Data Sheet 代理店DL日' ,
  'Mark Spec.', // 'Marking Spec.' ,
  'UL', // 'Marking Spec. WECJ準備完了日' ,
  'DL', // 'Marking Spec. 代理店DL日' ,
  'Wafer' ,
  'T&R' ,
  'UL', // 'T&R WECJ準備完了日' ,
  'DL', // 'T&R 代理店DL日' ,
  'Tray' ,
  'UL', // 'Tray WECJ準備完了日' ,
  'DL', // 'Tray 代理店DL日' ,
  'Tray図' ,
  'UL', // 'Tray図 WECJ準備完了日' ,
  'DL', // 'Tray図 代理店DL日' ,
  'Tube', // 'Stick/Tube' ,
  'UL', // 'Stick/Tube WECJ準備完了日' ,
  'DL', // 'Stick/Tube 代理店DL日' ,
  'Reflow Profile', // 'IR Reflow Profile (実装仕様)' ,
  'UL', // 'IR Reflow Profile (実装仕様) WECJ準備完了日' ,
  'DL', // 'IR Reflow Profile (実装仕様) 代理店DL日' ,
  '手はんだ', // '手はんだ条件' ,
  'UL', // '手はんだ条件 WECJ準備完了日' ,
  'DL', // '手はんだ条件 代理店DL日' ,
  'Chip Reliability' ,
  'UL', // 'Chip Reliability WECJ準備完了日' ,
  'DL', // 'Chip Reliability 代理店DL日' ,
  'PKG Reliability', // 'Package Reliability' ,
  'PKG Reliability Site 縛り有無', // 'Package Reliability Site 縛り有無' ,
  'UL', // 'Package Reliability WECJ準備完了日' ,
  'DL', // 'Package Reliability 代理店DL日' ,
  'Whisker', // 'Whisker Report' ,
  'UL', // 'Whisker Report WECJ準備完了日' ,
  'DL', // 'Whisker Report 代理店DL日' ,
  'Solderability', // 'Solderability/ MSL Report' ,
  'UL', // 'Solderability/ MSL Report WECJ準備完了日' ,
  'DL', // 'Solderability/ MSL Report 代理店DL日' ,
  'QC Flow', // 'QCS Flow' ,
  'UL', // 'QCS Flow WECJ準備完了日' ,
  'DL', // 'QCS Flow 代理店DL日' ,
  'Site Info', // 'Manuf.Site' ,
  'Site 縛り有無', // 'Manuf.Site 縛り有無' ,
  'Site 指定', // 'Manuf.Site 指定' ,
  'Site Sony GP', // 'Manuf.Site Sony GP' ,
  'UL', // 'Manuf.Site WECJ準備完了日' ,
  'DL', // 'Manuf.Site 代理店DL日' ,
   // 2021.05.27 改修No.3 列93(DL) と 94(Assy) の間に２列追加「Wafer」（テキスト入力）/「CP」（テキスト入力）
  'Wafer',
  'CP',  
  'Assy', // 'Assembly Site' ,
  'FT', // 'FT Site' ,
  'PKG X-Section', // 'Package Cross Section Drawings' ,
  'UL', // 'Package Cross Section Drawings WECJ準備完了日' ,
  'DL', // 'Package Cross Section Drawings 代理店DL日' ,
  'RoHS Compliance Non-Use Declaration' ,
  'UL', // 'RoHS Compliance Non-Use Declaration WECJ準備完了日' ,
  'DL', // 'RoHS Compliance Non-Use Declaration 代理店DL日' ,
  // 'RoHS Compliance Non-Use Declaration 顧客フォーマット WECJ準備完了日' ,
  // 'RoHS Compliance Non-Use Declaration 顧客フォーマット 代理店DL日' ,
  'RoHS Compliance Non-Use Declaration 顧客フォーマット' ,
  'REACH SVHC PFOS etc' ,
  'UL', // 'REACH SVHC PFOS etc WECJ準備完了日' ,
  'DL', // 'REACH SVHC PFOS etc 代理店DL日' ,
  // 'REACH SVHC PFOS etc 顧客フォーマット WECJ準備完了日' ,
  // 'REACH SVHC PFOS etc 顧客フォーマット 代理店DL日' ,
  'REACH SVHC PFOS etc 顧客フォーマット' ,
  'MCL', // 'Material Composition List' ,
  'UL', // 'Material Composition List WECJ準備完了日' ,
  'DL', // 'Material Composition List 代理店DL日' ,
  // 'Material Composition List 顧客フォーマット WECJ準備完了日' ,
  // 'Material Composition List 顧客フォーマット 代理店DL日' ,
  'MCL 顧客フォーマット', // 'Material Composition List 顧客フォーマット' ,
  'MSDS', // 'Environmental Analysis (MSDS)' ,
  'UL', // 'Environmental Analysis (MSDS) WECJ準備完了日' ,
  'DL', // 'Environmental Analysis (MSDS) 代理店DL日' ,
  'ICP', // 'ICP Analysis Data' ,
  'UL', // 'ICP Analysis Data WECJ準備完了日' ,
  'DL', // 'ICP Analysis Data 代理店DL日' ,
  'AIS', // 'JAMP AIS' ,
  'Ver.', // 'JAMP AIS Version情報' ,
  'UL', // 'JAMP AIS WECJ準備完了日' ,
  'DL', // 'JAMP AIS 代理店DL日' ,
  'chemSHERPA',
  'Ver.', // 'chemSHERPA Version情報' ,
  'UL', // 'chemSHERPA WECJ準備完了日' ,
  'DL', // 'chemSHERPA 代理店DL日' ,
  'JAMA' ,
  'Ver.', // 'JAMA Version情報' ,
  'UL', // 'JAMA WECJ準備完了日' ,
  'DL', // 'JAMA 代理店DL日' ,
  'IMDS' ,
  'Ver.', // 'IMDS Version情報' ,
  'UL', // 'IMDS WECJ準備完了日' ,
  'DL', // 'IMDS 代理店DL日' ,
  'CMRT', // 'EICC/CMRT' ,
  'Ver.', // 'EICC/CMRT Version情報' ,
  'UL', // 'EICC/CMRT WECJ準備完了日' ,
  'DL', // 'EICC/CMRT 代理店DL日' ,
  'CRT', // '紛争鉱物　(CRT コバルト)' ,
  'Ver.', // '紛争鉱物　(CRT コバルト) Version情報' ,
  'UL', // '紛争鉱物　(CRT コバルト) WECJ準備完了日' ,
  'DL', // '紛争鉱物　(CRT コバルト) 代理店DL日' ,
  'その他環境調査資料' ,
  'その他環境調査資料 Specify' ,
  'その他環境調査資料 顧客フォーマット' ,
  'その他環境UL', // 'その他環境調査資料 WECJ準備完了日' ,
  'その他環境DL', // 'その他環境調査資料 代理店DL日' ,
  // 'その他環境調査資料 顧客フォーマット（リンク）' ,
  '他環境顧客ﾌｫｰﾑUL', // 'その他 顧客フォーマット WECJ準備完了日',
  '他環境顧客ﾌｫｰﾑDL', // 'その他 顧客フォーマット 代理店DL日',
  'Other(1)' ,
  'UL', // Other1 WECJ準備完了日
  'DL', // Other1 代理店DL日
  'Other(2)' ,
  'UL', // Other2 WECJ準備完了日
  'DL', // Other2 代理店DL日
  'Other(3)' ,
  'UL', // Other3 WECJ準備完了日
  'DL', // Other3 代理店DL日
  'Other(4)' ,
  'UL', // Other4 WECJ準備完了日
  'DL', // Other4 代理店DL日
  'Other(5)' ,
  'UL', // Other5 WECJ準備完了日
  'DL', // Other5 代理店DL日
  'Other(6)' ,
  'UL', // Other6 WECJ準備完了日
  'DL', // Other6 代理店DL日
  'Other(7)' ,
  'UL', // Other7 WECJ準備完了日
  'DL', // Other7 代理店DL日
  'Other(8)' ,
  'UL', // Other8 WECJ準備完了日
  'DL', // Other8 代理店DL日
  'Other(9)' ,
  'UL', // Other9 WECJ準備完了日
  'DL', // Other9 代理店DL日
  'Other(10)' ,
  'UL', // Other10 WECJ準備完了日
  'DL', // Other10 代理店DL日
  'Other(11)' ,
  'UL', // Other11 WECJ準備完了日
  'DL', // Other11 代理店DL日
  'Other(12)' ,
  'UL', // Other12 WECJ準備完了日
  'DL', // Other12 代理店DL日
  'Other(13)' ,
  'UL', // Other13 WECJ準備完了日
  'DL', // Other13 代理店DL日
  'Other(14)' ,
  'UL', // Other14 WECJ準備完了日
  'DL', // Other14 代理店DL日
  'Other(15)' ,
  'UL', // Other15 WECJ準備完了日
  'DL', // Other15 代理店DL日
  'Comment' ,
  'WECJ Remark' ,
  // 'Agency' ,
  // 'Agency_PIC' ,
  // 'Document_History' ,
  // '作成日' 
];





// 2021.05.27 H.Nakazato 列追加（Manuf_Site_Wafer、Manuf_Site_CP、Other_1 の WECJ_Day、Dist_Day）
// 2021.06.03 K.Ueoku 「WECJ SALES」に「内田　知樹」追加（5/19リリース分）
// 2021.06.03 K.Ueoku 列追加（Other_2 ～ 15 の WECJ_Day、Dist_Day）
HandsonsData_Documents.columns_Data = [
  {
    data: 'delete_Flag',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  // {data: 'partition_key', readOnly: true},
  // {data: 'mode'},
  // {data: 'Request_No'},
  // {data: 'Project_No'},
  {data: 'create_time', readOnly: false },
  {data: 'Uniqu_No', readOnly: false},
  // {data: 'Uniqu_No_2', readOnly: true},
  // {data: 'create_time', readOnly: true },
  { 
    data: 'status',
    type: 'dropdown',
    // source: ['作成中','登録申請中','修正申請中','キャンセル申請中', 'キャンセル受付済', '差戻し', '受付済', 'ダウンロード可能' ],
    source: ['作成中','登録申請中', 'キャンセル申請中', 'キャンセル受付済', '差戻し', '受付済', 'ダウンロード可能' ],
  },
  {data: 'Disti_ID'},
  // {data: 'type', readOnly: false},
  { 
    data: 'type',
    type: 'dropdown',
    source: ['代理店', '仕様', '環境', '技術' ],
  },
  { 
    data: 'Request_Date',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    className:'htRight',
    readOnly: false 
  },

  {data: 'Request_Format'},
  {data: 'Existing_DS_P_J_DS'},

  {data: 'CQS_QASR_cansel'},
  {data: 'Customer_ID'},
  {data: 'Customer_Group'},
  {data: 'Customer_atesaki'},
  {data: 'Group_Division_or_End_Customer'},
  {data: 'Disti_Name'},
  {data: 'Disti_PIC'},
  {data: 'Mail_Agency'},
  { 
    data: 'WECJ_Sales_Person',
    type: 'dropdown',
    source: null,
  },
  {data: 'JQ_Person'},
  {data: 'Product_Type'},
  {data: 'Version'},
  {data: 'P_N'},
  // {data: 'Product_List'},
  // {data: 'Product_List_WECJ_Day'},
  // {data: 'Product_List_Dist_Day'},
  {data: 'Customer_P_N'},
  {data: 'Customer_DS_Application_Remarks'},
  {data: 'Package'},
  {data: 'Product_name'},
  {data: 'Die_Version'},
  { 
    data: 'Application_Code',
    type: 'dropdown',
    source: null,
  },
  {data: 'Application_Category', readOnly: false },
  {data: 'Application_Category_1', readOnly: false },
  {data: 'Application_Category_2', readOnly: false },
  { 
    data: 'HQ_Request_Date',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    className:'htRight',
    readOnly: false 
  },
  { 
    data: 'HQ_Receipt_Date',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    className:'htRight',
    readOnly: false 
  },
  {data: 'HQ_Request_TAT', className:'htRight', readOnly: true },

  { 
    data: 'HQ_Actual_Receipt_Date',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    className:'htRight',
    readOnly: false 
  },
  {data: 'HQ_Request_Actual_Closed_TAT', className:'htRight', readOnly: true},

  { 
    data: 'Due_Date',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    className:'htRight',
    readOnly: false 
  },
  { 
    data: 'Issued_Final',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    className:'htRight',
    readOnly: false 
  },

  {data: 'ALL_TAT_CQS_ari_WECJ_WEC_holiday', className:'htRight'},
  {data: 'ALL_TAT_CQS_nashi_WECJ_holiday', className:'htRight'},
  {data: 'ALL_TAT', className:'htRight'},
  {data: 'WECJ_TAT', className:'htRight'},
  {data: 'Delay_from_CRD', className:'htRight'},
  { 
    data: 'Hyoushi_HoukokusyoHenkyaku',
    type: 'date',
    dateFormat: 'YYYY-MM-DD',
    className:'htRight',
    readOnly: false 
  },
  {
    data: 'Hyoushi',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Hyoushi_WECJ_Day'},
  {data: 'Hyoushi_Dist_Day'},
  {
    data: 'Data_Sheet',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Data_Sheet_WECJ_Day'},
  {data: 'Data_Sheet_Dist_Day'},
  {
    data: 'Marking_Spec',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Marking_Spec_WECJ_Day'},
  {data: 'Marking_Spec_Dist_Day'},
  {data: 'Wafer'},
  {
    data: 'T_R',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'T_R_WECJ_Day'},
  {data: 'T_R_Dist_Day'},
  {
    data: 'Tray',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Tray_WECJ_Day'},
  {data: 'Tray_Dist_Day'},
  {
    data: 'Tray_Zu',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Tray_Zu_WECJ_Day'},
  {data: 'Tray_Zu_Dist_Day'},
  {
    data: 'Stick_Tube',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Stick_Tube_WECJ_Day'},
  {data: 'Stick_Tube_Dist_Day'},
  {
    data: 'IR_Reflow_Profile',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'IR_Reflow_Profile_WECJ_Day'},
  {data: 'IR_Reflow_Profile_Dist_Day'},
  {
    data: 'IR_Reflow_Profile_manual',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'IR_Reflow_Profile_manual_WECJ_Day'},
  {data: 'IR_Reflow_Profile_manual_Dist_Day'},
  {
    data: 'Chip_Reliability',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Chip_Reliability_WECJ_Day'},
  {data: 'Chip_Reliability_Dist_Day'},
  {
    data: 'Package_Reliability',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Package_Reliability_Site_Flag',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Package_Reliability_WECJ_Day'},
  {data: 'Package_Reliability_Dist_Day'},
  {
    data: 'Whisker_Report',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Whisker_Report_WECJ_Day'},
  {data: 'Whisker_Report_Dist_Day'},
  {
    data: 'Solderability_MSL_Report',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Solderability_MSL_Report_WECJ_Day'},
  {data: 'Solderability_MSL_Report_Dist_Day'},
  {
    data: 'QCS_Flow',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'QCS_Flow_WECJ_Day'},
  {data: 'QCS_Flow_Dist_Day'},
  {
    data: 'Manuf_Site',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Manuf_Site_Flag',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Manuf_Site_Shitei'},
  {
    data: 'Manuf_Site_Sony_GP',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Manuf_Site_WECJ_Day'},
  {data: 'Manuf_Site_Dist_Day'},
  // 2021.05.27 改修No.3 2項目追加
  {data: 'Manuf_Site_Wafer'},
  {data: 'Manuf_Site_CP'},
  {data: 'Assembly_Site'},
  {data: 'FT_Site'},
  {
    data: 'Package_Cross_Section_Drawings',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Package_Cross_Section_Drawings_WECJ_Day'},
  {data: 'Package_Cross_Section_Drawings_Dist_Day'},
  {
    data: 'RoHS_Compliance_Non_Use_Declaration',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  // {data: 'RoHS_Compliance_Non_Use_Declaration_WECJ_Day'},
  // {data: 'RoHS_Compliance_Non_Use_Declaration_Dist_Day'},
  {data: 'RoHS_Compliance_Non_Use_Declaration_Format_WECJ_Day'},
  {data: 'RoHS_Compliance_Non_Use_Declaration_Format_Dist_Day'},
  {
    data: 'RoHS_Compliance_Non_Use_Declaration_Format',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },

  {
    data: 'REACH_SVHC_PFOS_etc',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  // {data: 'REACH_SVHC_PFOS_etc_WECJ_Day'},
  // {data: 'REACH_SVHC_PFOS_etc_Dist_Day'},
  {data: 'REACH_SVHC_PFOS_etc_Format_WECJ_Day'},
  {data: 'REACH_SVHC_PFOS_etc_Format_Dist_Day'},
  {
    data: 'REACH_SVHC_PFOS_etc_Format',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Material_Composition_List',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  // {data: 'Material_Composition_List_WECJ_Day'},
  // {data: 'Material_Composition_List_Dist_Day'},
  {data: 'Material_Composition_List_Format_WECJ_Day'},
  {data: 'Material_Composition_List_Format_Dist_Day'},
  {
    data: 'Material_Composition_List_Format',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Environmental_Analysis_MSDS',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Environmental_Analysis_MSDS_WECJ_Day'},
  {data: 'Environmental_Analysis_MSDS_Dist_Day'},
  {
    data: 'ICP_Analysis_Data',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'ICP_Analysis_Data_WECJ_Day'},
  {data: 'ICP_Analysis_Data_Dist_Day'},
  {
    data: 'JAMP_AIS',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'JAMP_AIS_Version'},
  {data: 'JAMP_AIS_WECJ_Day'},
  {data: 'JAMP_AIS_Dist_Day'},
  {
    data: 'chemSHERPA',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'chemSHERPA_Version'},
  {data: 'chemSHERPA_WECJ_Day'},
  {data: 'chemSHERPA_Dist_Day'},
  {
    data: 'JAMA',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'JAMA_Version'},
  {data: 'JAMA_WECJ_Day'},
  {data: 'JAMA_Dist_Day'},
  {
    data: 'IMDS',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'IMDS_Version'},
  {data: 'IMDS_WECJ_Day'},
  {data: 'IMDS_Dist_Day'},
  {
    data: 'EICC_CMRT',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'EICC_CMRT_Version'},
  {data: 'EICC_CMRT_WECJ_Day'},
  {data: 'EICC_CMRT_Dist_Day'},
  {
    data: 'FunsouKoubutu_CRT',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'FunsouKoubutu_CRT_Version'},
  {data: 'FunsouKoubutu_CRT_WECJ_Day'},
  {data: 'FunsouKoubutu_CRT_Dist_Day'},
  {
    data: 'SonotaKankyoChosaShiryo',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'SonotaKankyoChosaShiryo_Specify'},
  {
    data: 'SonotaKankyoChosaShiryo_Format',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'SonotaKankyoChosaShiryo_Format_WECJ_Day'},
  {data: 'SonotaKankyoChosaShiryo_Format_Dist_Day'},
  {data: 'Other_reliability_report_WECJ_Day'},
  {data: 'Other_reliability_report_Dist_Day'},
  {data: 'Other_1'},
  {data: 'Other_1_WECJ_Day'},
  {data: 'Other_1_Dist_Day'},
  {data: 'Other_2'},
  {data: 'Other_2_WECJ_Day'},
  {data: 'Other_2_Dist_Day'}, 
  {data: 'Other_3'},
  {data: 'Other_3_WECJ_Day'},
  {data: 'Other_3_Dist_Day'}, 
  {data: 'Other_4'},
  {data: 'Other_4_WECJ_Day'},
  {data: 'Other_4_Dist_Day'}, 
  {data: 'Other_5'},
  {data: 'Other_5_WECJ_Day'},
  {data: 'Other_5_Dist_Day'}, 
  {data: 'Other_6'},
  {data: 'Other_6_WECJ_Day'},
  {data: 'Other_6_Dist_Day'}, 
  {data: 'Other_7'},
  {data: 'Other_7_WECJ_Day'},
  {data: 'Other_7_Dist_Day'}, 
  {data: 'Other_8'},
  {data: 'Other_8_WECJ_Day'},
  {data: 'Other_8_Dist_Day'}, 
  {data: 'Other_9'},
  {data: 'Other_9_WECJ_Day'},
  {data: 'Other_9_Dist_Day'}, 
  {data: 'Other_10'},
  {data: 'Other_10_WECJ_Day'},
  {data: 'Other_10_Dist_Day'}, 
  {data: 'Other_11'},
  {data: 'Other_11_WECJ_Day'},
  {data: 'Other_11_Dist_Day'}, 
  {data: 'Other_12'},
  {data: 'Other_12_WECJ_Day'},
  {data: 'Other_12_Dist_Day'}, 
  {data: 'Other_13'},
  {data: 'Other_13_WECJ_Day'},
  {data: 'Other_13_Dist_Day'}, 
  {data: 'Other_14'},
  {data: 'Other_14_WECJ_Day'},
  {data: 'Other_14_Dist_Day'}, 
  {data: 'Other_15'},
  {data: 'Other_15_WECJ_Day'},
  {data: 'Other_15_Dist_Day'}, 
  {data: 'Comment'},
  {data: 'WECJ_Remark'},
  // {data: 'Agency', type: 'dropdown', source:["CS1","DIRECT","FFEI","HGWR","KFT","OTR","REC","RSN","WADA","WEB","ﾏｸﾆｶ","三信"],},
  // {data: 'Agency_PIC'},
  // {data: 'Document_History'},
];


HandsonsData_Documents.data = [
];



HandsonsData_Documents.sampledata = [
  {
    delete_Flag: "0",
    create_time: '',
    status: "作成中",
    Uniqu_No: "",
    ALL_TAT: "",
    ALL_TAT_CQS_ari_WECJ_WEC_holiday: "",
    ALL_TAT_CQS_nashi_WECJ_holiday: "",
    Application_Category: "",
    Application_Category_1: "",
    Application_Category_2: "",
    Application_Code: "",
    Assembly_Site: "",
    chemSHERPA: "0",
    chemSHERPA_File: [ "" ],
    chemSHERPA_WECJ_Day: "",
    chemSHERPA_Dist_Day: "",
    chemSHERPA_Version: "",
    Chip_Reliability: "0",
    Chip_Reliability_File: [ "" ],
    Chip_Reliability_WECJ_Day: "",
    Chip_Reliability_Dist_Day: "",
    Comment: "",
    CQS_QASR_cansel: "",
    Customer_atesaki: "",
    Customer_DS_Application_Remarks: "",
    Customer_Group: "",
    Customer_ID: "",
    Customer_P_N: "",
    Data_Sheet: "0",
    Data_Sheet_File: [ "" ],
    Data_Sheet_WECJ_Day: "",
    Data_Sheet_Dist_Day: "",
    Delay_from_CRD: "",
    Die_Version: "",
    Disti_ID: "",
    Disti_Name: "",
    Disti_PIC: "",
    Disti_Email: "",
    Density: "",
    Part_Name: "",
    Due_Date: "",
    EICC_CMRT: "0",
    EICC_CMRT_File: [ "" ],
    EICC_CMRT_WECJ_Day: "",
    EICC_CMRT_Dist_Day: "",
    EICC_CMRT_Version: "",
    Environmental_Analysis_MSDS: "0",
    Environmental_Analysis_MSDS_File: [ "" ],
    Environmental_Analysis_MSDS_WECJ_Day: "",
    Environmental_Analysis_MSDS_Dist_Day: "",
    Existing_DS_P_J_DS: "",
    FT_Site: "",
    FunsouKoubutu_CRT: "0",
    FunsouKoubutu_CRT_File: [ "" ],
    FunsouKoubutu_CRT_WECJ_Day: "",
    FunsouKoubutu_CRT_Dist_Day: "",
    FunsouKoubutu_CRT_Version: "",
    Group_Division_or_End_Customer: "",
    HQ_Receipt_Date: "",
    HQ_Request_Date: "",
    HQ_Request_TAT: "",
    HQ_Actual_Receipt_Date: "",
    HQ_Request_Actual_Closed_TAT: "",
    Hyoushi_HoukokusyoHenkyaku: "",
    Hyoushi: "0",
    Hyoushi_File: [ "" ],
    Hyoushi_WECJ_Day: "",
    Hyoushi_Dist_Day: "",
    ICP_Analysis_Data: "0",
    ICP_Analysis_Data_File: [ "" ],
    ICP_Analysis_Data_WECJ_Day: "",
    ICP_Analysis_Data_Dist_Day: "",
    IMDS: "0",
    IMDS_File: [ "" ],
    IMDS_WECJ_Day: "",
    IMDS_Dist_Day: "",
    IMDS_Version: "",
    IR_Reflow_Profile: "0",
    IR_Reflow_Profile_File: [ "" ],
    IR_Reflow_Profile_WECJ_Day: "",
    IR_Reflow_Profile_Dist_Day: "",
    IR_Reflow_Profile_manual: "0",
    IR_Reflow_Profile_manual_File: [ "" ],
    IR_Reflow_Profile_manual_WECJ_Day: "",
    IR_Reflow_Profile_manual_Dist_Day: "",
    Irai_bunrui: "",
    Issued_Final: "",
    JAMA: "0",
    JAMA_File: [ "" ],
    JAMA_WECJ_Day: "",
    JAMA_Dist_Day: "",
    JAMA_Version: "",
    JAMP_AIS: "0",
    JAMP_AIS_File: [ "" ],
    //2021.05.27 改修No3 2項目追加
    Manuf_Site_Wafer: "",
    Manuf_Site_CP: "",
    JAMP_AIS_WECJ_Day: "",
    JAMP_AIS_Dist_Day: "",
    JAMP_AIS_Version: "",
    JQ_Person: "",
    Manuf_Site: "0",
    Manuf_Site_Shitei: "",
    Manuf_Site_Flag: "0",
    Manuf_Site_Sony_GP: "0",
    Manuf_Site_File: [ "" ],
    Manuf_Site_WECJ_Day: "",
    Manuf_Site_Dist_Day: "",
    Marking_Spec: "0",
    Marking_Spec_File: [ "" ],
    Marking_Spec_WECJ_Day: "",
    Marking_Spec_Dist_Day: "",
    Material_Composition_List: "0",
    Material_Composition_List_File: [ "" ],
    Material_Composition_List_WECJ_Day: "",
    Material_Composition_List_Dist_Day: "",
    Material_Composition_List_Format: "0",
    Material_Composition_List_Format_File: [ "","","","","","","","","","" ],
    Material_Composition_List_Format_WECJ_Day: "",
    Material_Composition_List_Format_Dist_Day: "",
    Material_Composition_List_Format_WECJ_File: [ "" ],
    mode: "",
    Other_1: "",
    Other_10: "",
    Other_11: "",
    Other_12: "",
    Other_13: "",
    Other_14: "",
    Other_15: "",
    Other_2: "",
    Other_3: "",
    Other_4: "",
    Other_5: "",
    Other_6: "",
    Other_7: "",
    Other_8: "",
    Other_9: "",
    P_N: "",
    Package: "",
    Package_Cross_Section_Drawings: "0",
    Package_Cross_Section_Drawings_File: [ "" ],
    Package_Cross_Section_Drawings_WECJ_Day: "",
    Package_Cross_Section_Drawings_Dist_Day: "",
    Package_Reliability: "0",
    Package_Reliability_File: [ "" ],
    Package_Reliability_WECJ_Day: "",
    Package_Reliability_Dist_Day: "",
    Package_Reliability_Site_Flag: "0",
    partition_key: "",
    Product_name: "",
    Product_Type: "",
    Product_List: "",
    Product_List_WECJ_Day: "",
    Product_List_Dist_Day: "",
    Project_No: "",
    QCS_Flow: "0",
    QCS_Flow_File: [ "" ],
    QCS_Flow_WECJ_Day: "",
    QCS_Flow_Dist_Day: "",
    REACH_SVHC_PFOS_etc: "0",
    REACH_SVHC_PFOS_etc_File: [ "" ],
    REACH_SVHC_PFOS_etc_WECJ_Day: "",
    REACH_SVHC_PFOS_etc_Dist_Day: "",
    REACH_SVHC_PFOS_etc_Format: "0",
    REACH_SVHC_PFOS_etc_Format_File: [ "","","","","","","","","","" ],
    REACH_SVHC_PFOS_etc_Format_WECJ_Day: "",
    REACH_SVHC_PFOS_etc_Format_Dist_Day: "",
    REACH_SVHC_PFOS_etc_Format_WECJ_File: [ "" ],
    Request_Format: "",
    Request_message_received_date: "",
    Request_Date: "",
    Request_No: "",
    RoHS_Compliance_Non_Use_Declaration: "0",
    RoHS_Compliance_Non_Use_Declaration_File: [ "" ],
    RoHS_Compliance_Non_Use_Declaration_WECJ_Day: "",
    RoHS_Compliance_Non_Use_Declaration_Dist_Day: "",
    RoHS_Compliance_Non_Use_Declaration_Format: "0",
    RoHS_Compliance_Non_Use_Declaration_Format_File: [ "","","","","","","","","","" ],
    RoHS_Compliance_Non_Use_Declaration_Format_WECJ_Day: "",
    RoHS_Compliance_Non_Use_Declaration_Format_Dist_Day: "",
    RoHS_Compliance_Non_Use_Declaration_Format_WECJ_File: [ "" ],
    Solderability_MSL_Report: "0",
    Solderability_MSL_Report_File: [ "" ],
    Solderability_MSL_Report_WECJ_Day: "",
    Solderability_MSL_Report_Dist_Day: "",
    SonotaKankyoChosaShiryo: "0",
    SonotaKankyoChosaShiryo_Specify: "",
    SonotaKankyoChosaShiryo_Format: "0",
    SonotaKankyoChosaShiryo_Format_File: [ "","","","","","","","","","" ],
    SonotaKankyoChosaShiryo_Format_WECJ_Day: "",
    SonotaKankyoChosaShiryo_Format_Dist_Day: "",
    SonotaKankyoChosaShiryo_Format_WECJ_File: [ "" ],
    SonotaKankyoChosaShiryo_Link: "",
    Other_reliability_report_File: [ "","","","","","","","","","" ],
    Other_reliability_report_WECJ_Day: "",
    Other_reliability_report_Dist_Day: "",
    Other_reliability_report_WECJ_File: [ "" ],
    // 2021.06.03 追加
    Other_1_File: [ "" ],
    Other_1_WECJ_Day: "",
    Other_1_Dist_Day: "",
    Other_1_WECJ_File: [ "" ],
    Other_2_File: [ "" ],
    Other_2_WECJ_Day: "",
    Other_2_Dist_Day: "",
    Other_2_WECJ_File: [ "" ],
    Other_3_File: [ "" ],
    Other_3_WECJ_Day: "",
    Other_3_Dist_Day: "",
    Other_3_WECJ_File: [ "" ],
    Other_4_File: [ "" ],
    Other_4_WECJ_Day: "",
    Other_4_Dist_Day: "",
    Other_4_WECJ_File: [ "" ],
    Other_5_File: [ "" ],
    Other_5_WECJ_Day: "",
    Other_5_Dist_Day: "",
    Other_5_WECJ_File: [ "" ],
    Other_6_File: [ "" ],
    Other_6_WECJ_Day: "",
    Other_6_Dist_Day: "",
    Other_6_WECJ_File: [ "" ],
    Other_7_File: [ "" ],
    Other_7_WECJ_Day: "",
    Other_7_Dist_Day: "",
    Other_7_WECJ_File: [ "" ],
    Other_8_File: [ "" ],
    Other_8_WECJ_Day: "",
    Other_8_Dist_Day: "",
    Other_8_WECJ_File: [ "" ],
    Other_9_File: [ "" ],
    Other_9_WECJ_Day: "",
    Other_9_Dist_Day: "",
    Other_9_WECJ_File: [ "" ],
    Other_10_File: [ "" ],
    Other_10_WECJ_Day: "",
    Other_10_Dist_Day: "",
    Other_10_WECJ_File: [ "" ],
    Other_11_File: [ "" ],
    Other_11_WECJ_Day: "",
    Other_11_Dist_Day: "",
    Other_11_WECJ_File: [ "" ],
    Other_12_File: [ "" ],
    Other_12_WECJ_Day: "",
    Other_12_Dist_Day: "",
    Other_12_WECJ_File: [ "" ],
    Other_13_File: [ "" ],
    Other_13_WECJ_Day: "",
    Other_13_Dist_Day: "",
    Other_13_WECJ_File: [ "" ],
    Other_14_File: [ "" ],
    Other_14_WECJ_Day: "",
    Other_14_Dist_Day: "",
    Other_14_WECJ_File: [ "" ],
    Other_15_File: [ "" ],
    Other_15_WECJ_Day: "",
    Other_15_Dist_Day: "",
    Other_15_WECJ_File: [ "" ],
    Stick_Tube: "0",
    Stick_Tube_File: [ "" ],
    Stick_Tube_WECJ_Day: "",
    Stick_Tube_Dist_Day: "",
    T_R: "0",
    T_R_File: [ "" ],
    T_R_WECJ_Day: "",
    T_R_Dist_Day: "",
    Tray: "0",
    Tray_File: [ "" ],
    Tray_WECJ_Day: "",
    Tray_Dist_Day: "",
    Tray_Zu: "0",
    Tray_Zu_File: [ "" ],
    Tray_Zu_WECJ_Day: "",
    Tray_Zu_Dist_Day: "",
    type: "",
    Version: "",
    Wafer: "",
    WECJ_Sales_Person: "",
    WECJ_TAT: "",
    Whisker_Report: "0",
    Whisker_Report_File: [ "" ],
    Whisker_Report_WECJ_Day: "",
    Whisker_Report_Dist_Day: "",
    WECJ_Remark: "",
    Mail_Agency: "",
    yobi: ""
  },
];


HandsonsData_Documents.colHeaders_Name_2 = [
  // 'No1<br>partition_key',
  // 'create_time',
  // 'mode',
  // 'type',
  // 'Request_No',
  // 'Project_No',
  // 'CQS_QASR_cansel' ,
  'Control No',
  '完了日<br>Issued Final' ,
  '状況<br>Status',

  '依頼日<br>Request message<br>received date' ,
  '依頼書番号<br>Request#' ,
  // 'Customer_ID',
  // 'Customer_Group',
  '特約店担当者<br>Disti PIC' ,
  '顧客名<br>Customer Name',
  '製品型番<br>P/N' ,
  'Application Category 1' ,
  'Application Category 2' ,
  'プロジェクト（仕様書）No<br>Existing DS #(P/J#) DS #' ,
  'WECJ担当Sales<br>WECJ PIC' ,
  '希望納期<br>Request Due Date' ,
  // 'Group_Division_or_End_Customer' ,
  // 'Agency' ,
  // 'JQ_Person' ,
  // 'Product_Type' ,
  // 'Version' ,
  // 'Customer_P_N' ,
  // 'Customer_DS_Application_Remarks' ,
  // 'Package' ,
  // 'Product_name' ,
  // 'Disti_ID' ,
  // 'Die_Version' ,
  // 'Application_Code' ,
  // 'Application_Category' ,
  // 'HQ_Reques_Date' ,
  // 'HQ_Receipt_Date' ,
  // 'HQ_Request_TAT' ,
  // 'Due_Date' ,
  // 'ALL_TAT_CQS_ari_WECJ_WEC_holiday' ,
  // 'ALL_TAT_CQS_nashi_WECJ_holiday' ,
  // 'ALL_TAT' ,
  // 'WECJ_TAT' ,
  // 'Delay_from_CRD' ,
  // 'Irai_bunrui' ,
  // 'Hyoushi_HoukokusyoHenkyaku' ,
  // 'Hyoushi_Requested_by_C' ,
  // 'Data_Sheet_Requested_by_C' ,
  // 'Marking_Spec_Requested_by_C' ,
  // 'Wafer_Requested_by_C' ,
  // 'T_R_Requested_by_C' ,
  // 'Tray_Requested_by_C' ,
  // 'Tray_Zu_Requested_by_C' ,
  // 'Stick_Tube_Requested_by_C' ,
  // 'IR_Reflow_Profile_Requested_by_C' ,
  // 'Chip_Reliability_Requested_by_C' ,
  // 'Package_Reliability_Requested_by_C' ,
  // 'Package_Reliability_Requested_by_C_Site_Flag' ,
  // 'Whisker_Report_Requested_by_C' ,
  // 'Solderability_MSL_Report_Requested_by_C' ,
  // 'QCS_Flow_Requested_by_C' ,
  // 'Manuf_Site_Requested_by_C' ,
  // 'Manuf_Site_Requested_by_C_Site_Flag' ,
  // 'Manuf_Site_Requested_by_C_Sony_GP' ,
  // 'Assembly_Site' ,
  // 'FT_Site' ,
  // 'Package_Cross_Section_Drawings_Requested_by_C' ,
  // 'Document_History_Requested_by_C' ,
  // 'RoHS_Compliance_Non_Use_Declaration_Requested_by_C' ,
  // 'RoHS_Compliance_Non_Use_Declaration_Requested_by_C_Format' ,
  // 'REACH_SVHC_PFOS_etc_Requested_by_C' ,
  // 'REACH_SVHC_PFOS_etc_Requested_by_C_Format' ,
  // 'Material_Composition_List_Requested_by_C' ,
  // 'Material_Composition_List_Requested_by_C_Format' ,
  // 'Environmental_Analysis_MSDS_Requested_by_C' ,
  // 'ICP_Analysis_Data_Requested_by_C' ,
  // 'JAMP_AIS_Requested_by_C' ,
  // 'JAMP_AIS_Requested_by_C_Version' ,
  // 'chemSHERPA_Requested_by_C' ,
  // 'chemSHERPA_Requested_by_C_Version' ,
  // 'JAMA_Requested_by_C' ,
  // 'JAMA_Requested_by_C_Version' ,
  // 'IMDS_Requested_by_C' ,
  // 'IMDS_Requested_by_C_Version' ,
  // 'EICC_CMRT_Requested_by_C' ,
  // 'EICC_CMRT_Requested_by_C_Version' ,
  // 'SonotaKankyoChosaShiryo' ,
  // 'SonotaKankyoChosaShiryo_Format' ,
  // 'FunsouKoubutu_CRT' ,
  // 'Other_1' ,
  // 'Other_2' ,
  // 'Other_3' ,
  // 'Other_4' ,
  // 'Other_5' ,
  // 'Other_6' ,
  // 'Other_7' ,
  // 'Other_8' ,
  // 'Other_9' ,
  // 'Other_10' ,
  // 'Other_11' ,
  // 'Other_12' ,
  // 'Other_13' ,
  // 'Other_14' ,
  // 'Other_15' ,
  // 'Comment' ,
  // 'yobi' 
];



HandsonsData_Documents.columns_Data_2 = [
  // { data: 'partition_key', readOnly: true },
  // { data: 'create_time' },
  // { data: 'mode' },
  // { data: 'type' },
  // { data: 'Request_No' },
  // { data: 'Project_No' },
  // { data: 'CQS_QASR_cansel' },
  { data: 'Uniqu_No'},
  { data: 'Issued_Final' },
  { data: 'status' },
  { data: 'Request_Date' },
  { data: 'Request_Format' },
  { data: 'Disti_PIC' },
  { data: 'Customer_atesaki' },
  { data: 'P_N' },
  { data: 'Application_Category_1' },
  { data: 'Application_Category_2' },
  { data: 'Existing_DS_P_J_DS' },
  { data: 'WECJ_Sales_Person' },
  { data: 'Due_Date' },
  // { data: 'Customer_ID' },
  // { data: 'Customer_Group' },
  // { data: 'Group_Division_or_End_Customer' },
  // { data: 'Agency' },
  // { data: 'JQ_Person' },
  // { data: 'Product_Type' },
  // { data: 'Version' },
  // { data: 'Customer_P_N' },
  // { data: 'Customer_DS_Application_Remarks' },
  // { data: 'Package' },
  // { data: 'Product_name' },
  // { data: 'Disti_ID' },
  // { data: 'Die_Version' },
  // { data: 'Application_Code' },
  // { data: 'Application_Category' },
  // { data: 'HQ_Reques_Date' },
  // { data: 'HQ_Receipt_Date' },
  // { data: 'HQ_Request_TAT' },
  // { data: 'ALL_TAT_CQS_ari_WECJ_WEC_holiday' },
  // { data: 'ALL_TAT_CQS_nashi_WECJ_holiday' },
  // { data: 'ALL_TAT' },
  // { data: 'WECJ_TAT' },
  // { data: 'Delay_from_CRD' },
  // { data: 'Irai_bunrui' },
  // { data: 'Hyoushi_HoukokusyoHenkyaku' },
  // { data: 'Hyoushi_Requested_by_C' },
  // { data: 'Data_Sheet_Requested_by_C' },
  // { data: 'Marking_Spec_Requested_by_C' },
  // { data: 'Wafer_Requested_by_C' },
  // { data: 'T_R_Requested_by_C' },
  // { data: 'Tray_Requested_by_C' },
  // { data: 'Tray_Zu_Requested_by_C' },
  // { data: 'Stick_Tube_Requested_by_C' },
  // { data: 'IR_Reflow_Profile_Requested_by_C' },
  // { data: 'Chip_Reliability_Requested_by_C' },
  // { data: 'Package_Reliability_Requested_by_C' },
  // { data: 'Package_Reliability_Requested_by_C_Site_Flag' },
  // { data: 'Whisker_Report_Requested_by_C' },
  // { data: 'Solderability_MSL_Report_Requested_by_C' },
  // { data: 'QCS_Flow_Requested_by_C' },
  // { data: 'Manuf_Site_Requested_by_C' },
  // { data: 'Manuf_Site_Requested_by_C_Site_Flag' },
  // { data: 'Manuf_Site_Requested_by_C_Sony_GP' },
  // { data: 'Assembly_Site' },
  // { data: 'FT_Site' },
  // { data: 'Package_Cross_Section_Drawings_Requested_by_C' },
  // { data: 'Document_History_Requested_by_C' },
  // { data: 'RoHS_Compliance_Non_Use_Declaration_Requested_by_C' },
  // { data: 'RoHS_Compliance_Non_Use_Declaration_Requested_by_C_Format' },
  // { data: 'REACH_SVHC_PFOS_etc_Requested_by_C' },
  // { data: 'REACH_SVHC_PFOS_etc_Requested_by_C_Format' },
  // { data: 'Material_Composition_List_Requested_by_C' },
  // { data: 'Material_Composition_List_Requested_by_C_Format' },
  // { data: 'Environmental_Analysis_MSDS_Requested_by_C' },
  // { data: 'ICP_Analysis_Data_Requested_by_C' },
  // { data: 'JAMP_AIS_Requested_by_C' },
  // { data: 'JAMP_AIS_Requested_by_C_Version' },
  // { data: 'chemSHERPA_Requested_by_C' },
  // { data: 'chemSHERPA_Requested_by_C_Version' },
  // { data: 'JAMA_Requested_by_C' },
  // { data: 'JAMA_Requested_by_C_Version' },
  // { data: 'IMDS_Requested_by_C' },
  // { data: 'IMDS_Requested_by_C_Version' },
  // { data: 'EICC_CMRT_Requested_by_C' },
  // { data: 'EICC_CMRT_Requested_by_C_Version' },
  // { data: 'SonotaKankyoChosaShiryo' },
  // { data: 'SonotaKankyoChosaShiryo_Format' },
  // { data: 'FunsouKoubutu_CRT' },
  // { data: 'Other_1' },
  // { data: 'Other_2' },
  // { data: 'Other_3' },
  // { data: 'Other_4' },
  // { data: 'Other_5' },
  // { data: 'Other_6' },
  // { data: 'Other_7' },
  // { data: 'Other_8' },
  // { data: 'Other_9' },
  // { data: 'Other_10' },
  // { data: 'Other_11' },
  // { data: 'Other_12' },
  // { data: 'Other_13' },
  // { data: 'Other_14' },
  // { data: 'Other_15' },
  // { data: 'Comment' },
  // { data: 'yobi' }
];





HandsonsData_Documents.testdata = [
  {
    delete_Flag: "",
    create_time: '001',
    ALL_TAT: "ALL_TAT",
    ALL_TAT_CQS_ari_WECJ_WEC_holiday: "ALL_TAT_CQS_ari_WECJ_WEC_holiday",
    ALL_TAT_CQS_nashi_WECJ_holiday: "ALL_TAT_CQS_nashi_WECJ_holiday",
    Application_Category: "Application_Category",
    Application_Category_1: "Application_Category_1",
    Application_Category_2: "Application_Category_2",
    Application_Code: "Application_Code",
    Assembly_Site: "Assembly_Site",
    chemSHERPA: "chemSHERPA",
    chemSHERPA_Version: "chemSHERPA_Version",
    Chip_Reliability: "Chip_Reliability",
    Chip_Reliability_File: "Chip_Reliability_File",
    Comment: "Comment",
    CQS_QASR_cansel: "CQS_QASR_cansel",
    Customer_atesaki: "Customer_atesaki",
    Customer_DS_Application_Remarks: "Customer_DS_Application_Remarks",
    Customer_Group: "Customer_Group",
    Customer_ID: "Customer_ID",
    Customer_P_N: "Customer_P_N 01value",
    Data_Sheet: "Data_Sheet",
    Delay_from_CRD: "Delay_from_CRD",
    Die_Version: "Die_Version",
    Due_Date: "Due_Date",
    EICC_CMRT: "EICC_CMRT",
    EICC_CMRT_Version: "EICC_CMRT_Version",
    Environmental_Analysis_MSDS: "Environmental_Analysis_MSDS",
    Environmental_Analysis_MSDS_File: "Environmental_Analysis_MSDS_File",
    Existing_DS_P_J_DS: "Existing_DS_P_J_DS",
    FT_Site: "FT_Site",
    FunsouKoubutu_CRT: "FunsouKoubutu_CRT",
    Group_Division_or_End_Customer: "Group_Division_or_End_Customer",
    HQ_Receipt_Date: "HQ_Receipt_Date",
    HQ_Reques_Date: "HQ_Reques_Date",
    HQ_Request_TAT: "HQ_Request_TAT",
    Hyoushi_HoukokusyoHenkyaku: "Hyoushi_HoukokusyoHenkyaku",
    Hyoushi: "Hyoushi",
    ICP_Analysis_Data: "ICP_Analysis_Data",
    ICP_Analysis_Data_File: "ICP_Analysis_Data_File",
    IMDS: "IMDS",
    IMDS_Version: "IMDS_Version",
    IR_Reflow_Profile: "IR_Reflow_Profile",
    IR_Reflow_Profile_File: "IR_Reflow_Profile_File",
    IR_Reflow_Profile_manual: "IR_Reflow_Profile_manual",
    IR_Reflow_Profile_manual_File: "IR_Reflow_Profile_manual_File",
    Irai_bunrui: "Irai_bunrui",
    Issued_Final: "Issued_Final",
    JAMA: "JAMA",
    JAMA_Version: "JAMA_Version",
    JAMP_AIS: "JAMP_AIS",
    JAMP_AIS_Version: "JAMP_AIS_Version",
    JQ_Person: "JQ_Person",
    Manuf_Site: "Manuf_Site",
    Manuf_Site_Flag: "Manuf_Site_Flag",
    Manuf_Site_Sony_GP: "Manuf_Site_Sony_GP",
    Marking_Spec: "Marking_Spec",
    Marking_Spec_File: "Marking_Spec_File",
    Material_Composition_List: "Material_Composition_List",
    Material_Composition_List_File: "Material_Composition_List_File",
    Material_Composition_List_Format: "Material_Composition_List_Format",
    Material_Composition_List_Format_File: "Material_Composition_List_Format_File",
    mode: "mode",
    Other_1: "Other_1",
    Other_10: "Other_10",
    Other_11: "Other_11",
    Other_12: "Other_12",
    Other_13: "Other_13",
    Other_14: "Other_14",
    Other_15: "Other_15",
    Other_2: "Other_2",
    Other_3: "Other_3",
    Other_4: "Other_4",
    Other_5: "Other_5",
    Other_6: "Other_6",
    Other_7: "Other_7",
    Other_8: "Other_8",
    Other_9: "Other_9",
    P_N: "P_N",
    Package: "Package",
    Package_Cross_Section_Drawings: "Package_Cross_Section_Drawings",
    Package_Cross_Section_Drawings_File: "Package_Cross_Section_Drawings_File",
    Package_Reliability: "Package_Reliability",
    Package_Reliability_Site_Flag: "Package_Reliability_Site_Flag",
    partition_key: "Document",
    Product_name: "Product_name",
    Product_Type: "Product_Type",
    Project_No: "1",
    QCS_Flow: "QCS_Flow",
    QCS_Flow_File: "QCS_Flow_File",
    REACH_SVHC_PFOS_etc: "REACH_SVHC_PFOS_etc",
    REACH_SVHC_PFOS_etc_File: "REACH_SVHC_PFOS_etc_File",
    REACH_SVHC_PFOS_etc_Format: "REACH_SVHC_PFOS_etc_Format",
    REACH_SVHC_PFOS_etc_Format_File: "REACH_SVHC_PFOS_etc_Format_File",
    Request_Format: "Request_Format",
    Request_Date: "Request_Date",
    Request_No: "Request_No",
    RoHS_Compliance_Non_Use_Declaration: "RoHS_Compliance_Non_Use_Declaration",
    RoHS_Compliance_Non_Use_Declaration_File: "RoHS_Compliance_Non_Use_Declaration_File",
    RoHS_Compliance_Non_Use_Declaration_Format: "RoHS_Compliance_Non_Use_Declaration_Format",
    RoHS_Compliance_Non_Use_Declaration_Format_File: "RoHS_Compliance_Non_Use_Declaration_Format_File",
    Solderability_MSL_Report: "Solderability_MSL_Report",
    Solderability_MSL_Report_File: "Solderability_MSL_Report_File",
    SonotaKankyoChosaShiryo: "SonotaKankyoChosaShiryo",
    SonotaKankyoChosaShiryo_Format: "SonotaKankyoChosaShiryo_Format",
    Other_reliability_report_File:'',
    Other_1_File:'',
    Other_1_WECJ_Day:'',
    Other_2_File:'',
    Other_2_WECJ_Day:'',
    Other_3_File:'',
    Other_3_WECJ_Day:'',
    Other_4_File:'',
    Other_4_WECJ_Day:'',
    Other_5_File:'',
    Other_5_WECJ_Day:'',
    Other_6_File:'',
    Other_6_WECJ_Day:'',
    Other_7_File:'',
    Other_7_WECJ_Day:'',
    Other_8_File:'',
    Other_8_WECJ_Day:'',
    Other_9_File:'',
    Other_9_WECJ_Day:'',
    Other_10_File:'',
    Other_10_WECJ_Day:'',
    Other_11_File:'',
    Other_11_WECJ_Day:'',
    Other_12_File:'',
    Other_12_WECJ_Day:'',
    Other_13_File:'',
    Other_13_WECJ_Day:'',
    Other_14_File:'',
    Other_14_WECJ_Day:'',
    Other_15_File:'',
    Other_15_WECJ_Day:'',
    status: "A",
    Stick_Tube: "Stick_Tube",
    Stick_Tube_File: "Stick_Tube_File",
    T_R: "T_R",
    T_R_File: "T_R_File",
    Tray: "Tray",
    Tray_File: "Tray_File",
    Tray_Zu: "Tray_Zu",
    Tray_Zu_File: "Tray_Zu_File",
    type: "type",
    Version: "Version",
    Wafer: "Wafer",
    WECJ_Sales_Person: "WECJ_Sales_Person",
    WECJ_TAT: "WECJ_TAT",
    Whisker_Report: "Whisker_Report",
    Whisker_Report_File: "Whisker_Report_File",
    yobi: "yobi"
  },

  {
    delete_Flag: "",
    create_time: "002",
    ALL_TAT: "ALL_TAT",
    ALL_TAT_CQS_ari_WECJ_WEC_holiday: "ALL_TAT_CQS_ari_WECJ_WEC_holiday",
    ALL_TAT_CQS_nashi_WECJ_holiday: "ALL_TAT_CQS_nashi_WECJ_holiday",
    Application_Category: "Application_Category",
    Application_Category_1: "Application_Category_1",
    Application_Category_2: "Application_Category_2",
    Application_Code: "Application_Code",
    Assembly_Site: "Assembly_Site",
    chemSHERPA: "chemSHERPA",
    chemSHERPA_Version: "chemSHERPA_Version",
    Chip_Reliability: "Chip_Reliability",
    Chip_Reliability_File: "Chip_Reliability_File",
    Comment: "Comment",
    CQS_QASR_cansel: "CQS_QASR_cansel",
    Customer_atesaki: "Customer_atesaki",
    Customer_DS_Application_Remarks: "Customer_DS_Application_Remarks",
    Customer_Group: "Customer_Group",
    Customer_ID: "Customer_ID",
    Customer_P_N: "Customer_P_N 02",
    Data_Sheet: "Data_Sheet",
    Delay_from_CRD: "Delay_from_CRD",
    Die_Version: "Die_Version",
    Due_Date: "Due_Date",
    EICC_CMRT: "EICC_CMRT",
    EICC_CMRT_Version: "EICC_CMRT_Version",
    Environmental_Analysis_MSDS: "Environmental_Analysis_MSDS",
    Environmental_Analysis_MSDS_File: "Environmental_Analysis_MSDS_File",
    Existing_DS_P_J_DS: "Existing_DS_P_J_DS",
    FT_Site: "FT_Site",
    FunsouKoubutu_CRT: "FunsouKoubutu_CRT",
    Group_Division_or_End_Customer: "Group_Division_or_End_Customer",
    HQ_Receipt_Date: "HQ_Receipt_Date",
    HQ_Reques_Date: "HQ_Reques_Date",
    HQ_Request_TAT: "HQ_Request_TAT",
    Hyoushi_HoukokusyoHenkyaku: "Hyoushi_HoukokusyoHenkyaku",
    Hyoushi: "Hyoushi",
    ICP_Analysis_Data: "ICP_Analysis_Data",
    ICP_Analysis_Data_File: "ICP_Analysis_Data_File",
    IMDS: "IMDS",
    IMDS_Version: "IMDS_Version",
    IR_Reflow_Profile: "IR_Reflow_Profile",
    IR_Reflow_Profile_File: "IR_Reflow_Profile_File",
    IR_Reflow_Profile_manual: "IR_Reflow_Profile_manual",
    IR_Reflow_Profile_manual_File: "IR_Reflow_Profile_manual_File",
    Irai_bunrui: "Irai_bunrui",
    Issued_Final: "Issued_Final",
    JAMA: "JAMA",
    JAMA_Version: "JAMA_Version",
    JAMP_AIS: "JAMP_AIS",
    JAMP_AIS_Version: "JAMP_AIS_Version",
    JQ_Person: "JQ_Person",
    Manuf_Site: "Manuf_Site",
    Manuf_Site_Flag: "Manuf_Site_Flag",
    Manuf_Site_Sony_GP: "Manuf_Site_Sony_GP",
    Marking_Spec: "Marking_Spec",
    Marking_Spec_File: "Marking_Spec_File",
    Material_Composition_List: "Material_Composition_List",
    Material_Composition_List_File: "Material_Composition_List_File",
    Material_Composition_List_Format: "Material_Composition_List_Format",
    Material_Composition_List_Format_File: "Material_Composition_List_Format_File",
    mode: "mode",
    Other_1: "Other_1",
    Other_10: "Other_10",
    Other_11: "Other_11",
    Other_12: "Other_12",
    Other_13: "Other_13",
    Other_14: "Other_14",
    Other_15: "Other_15",
    Other_2: "Other_2",
    Other_3: "Other_3",
    Other_4: "Other_4",
    Other_5: "Other_5",
    Other_6: "Other_6",
    Other_7: "Other_7",
    Other_8: "Other_8",
    Other_9: "Other_9",
    P_N: "P_N",
    Package: "Package",
    Package_Cross_Section_Drawings: "Package_Cross_Section_Drawings",
    Package_Cross_Section_Drawings_File: "Package_Cross_Section_Drawings_File",
    Package_Reliability: "Package_Reliability",
    Package_Reliability_Site_Flag: "Package_Reliability_Site_Flag",
    partition_key: "Document",
    Product_name: "Product_name",
    Product_Type: "Product_Type",
    Project_No: "1",
    QCS_Flow: "QCS_Flow",
    QCS_Flow_File: "QCS_Flow_File",
    REACH_SVHC_PFOS_etc: "REACH_SVHC_PFOS_etc",
    REACH_SVHC_PFOS_etc_File: "REACH_SVHC_PFOS_etc_File",
    REACH_SVHC_PFOS_etc_Format: "REACH_SVHC_PFOS_etc_Format",
    REACH_SVHC_PFOS_etc_Format_File: "REACH_SVHC_PFOS_etc_Format_File",
    Request_Format: "Request_Format",
    Request_Date: "Request_Date",
    Request_No: "Request_No",
    RoHS_Compliance_Non_Use_Declaration: "RoHS_Compliance_Non_Use_Declaration",
    RoHS_Compliance_Non_Use_Declaration_File: "RoHS_Compliance_Non_Use_Declaration_File",
    RoHS_Compliance_Non_Use_Declaration_Format: "RoHS_Compliance_Non_Use_Declaration_Format",
    RoHS_Compliance_Non_Use_Declaration_Format_File: "RoHS_Compliance_Non_Use_Declaration_Format_File",
    Solderability_MSL_Report: "Solderability_MSL_Report",
    Solderability_MSL_Report_File: "Solderability_MSL_Report_File",
    SonotaKankyoChosaShiryo: "SonotaKankyoChosaShiryo",
    SonotaKankyoChosaShiryo_Format: "SonotaKankyoChosaShiryo_Format",
    status: "B",
    Stick_Tube: "Stick_Tube",
    Stick_Tube_File: "Stick_Tube_File",
    T_R: "T_R",
    T_R_File: "T_R_File",
    Tray: "Tray",
    Tray_File: "Tray_File",
    Tray_Zu: "Tray_Zu",
    Tray_Zu_File: "Tray_Zu_File",
    type: "type",
    Version: "Version",
    Wafer: "Wafer",
    WECJ_Sales_Person: "WECJ_Sales_Person",
    WECJ_TAT: "WECJ_TAT",
    Whisker_Report: "Whisker_Report",
    Whisker_Report_File: "Whisker_Report_File",
    yobi: "yobi"
  },

  {
    delete_Flag: "",
    create_time: "007",
    ALL_TAT: "ALL_TAT",
    ALL_TAT_CQS_ari_WECJ_WEC_holiday: "ALL_TAT_CQS_ari_WECJ_WEC_holiday",
    ALL_TAT_CQS_nashi_WECJ_holiday: "ALL_TAT_CQS_nashi_WECJ_holiday",
    Application_Category: "Application_Category",
    Application_Category_1: "Application_Category_1",
    Application_Category_2: "Application_Category_2",
    Application_Code: "Application_Code",
    Assembly_Site: "Assembly_Site",
    chemSHERPA: "chemSHERPA",
    chemSHERPA_Version: "chemSHERPA_Version",
    Chip_Reliability: "Chip_Reliability",
    Chip_Reliability_File: "Chip_Reliability_File",
    Comment: "Comment",
    CQS_QASR_cansel: "CQS_QASR_cansel",
    Customer_atesaki: "Customer_atesaki",
    Customer_DS_Application_Remarks: "Customer_DS_Application_Remarks",
    Customer_Group: "Customer_Group",
    Customer_ID: "Customer_ID",
    Customer_P_N: "Customer_P_N 07",
    Data_Sheet: "Data_Sheet",
    Delay_from_CRD: "Delay_from_CRD",
    Die_Version: "Die_Version",
    Due_Date: "Due_Date",
    EICC_CMRT: "EICC_CMRT",
    EICC_CMRT_Version: "EICC_CMRT_Version",
    Environmental_Analysis_MSDS: "Environmental_Analysis_MSDS",
    Environmental_Analysis_MSDS_File: "Environmental_Analysis_MSDS_File",
    Existing_DS_P_J_DS: "Existing_DS_P_J_DS",
    FT_Site: "FT_Site",
    FunsouKoubutu_CRT: "FunsouKoubutu_CRT",
    Group_Division_or_End_Customer: "Group_Division_or_End_Customer",
    HQ_Receipt_Date: "HQ_Receipt_Date",
    HQ_Reques_Date: "HQ_Reques_Date",
    HQ_Request_TAT: "HQ_Request_TAT",
    Hyoushi_HoukokusyoHenkyaku: "Hyoushi_HoukokusyoHenkyaku",
    Hyoushi: "Hyoushi",
    ICP_Analysis_Data: "ICP_Analysis_Data",
    ICP_Analysis_Data_File: "ICP_Analysis_Data_File",
    IMDS: "IMDS",
    IMDS_Version: "IMDS_Version",
    IR_Reflow_Profile: "IR_Reflow_Profile",
    IR_Reflow_Profile_File: "IR_Reflow_Profile_File",
    IR_Reflow_Profile_manual: "IR_Reflow_Profile_manual",
    IR_Reflow_Profile_manual_File: "IR_Reflow_Profile_manual_File",
    Irai_bunrui: "Irai_bunrui",
    Issued_Final: "Issued_Final",
    JAMA: "JAMA",
    JAMA_Version: "JAMA_Version",
    JAMP_AIS: "JAMP_AIS",
    JAMP_AIS_Version: "JAMP_AIS_Version",
    JQ_Person: "JQ_Person",
    Manuf_Site: "Manuf_Site",
    Manuf_Site_Flag: "Manuf_Site_Flag",
    Manuf_Site_Sony_GP: "Manuf_Site_Sony_GP",
    Marking_Spec: "Marking_Spec",
    Marking_Spec_File: "Marking_Spec_File",
    Material_Composition_List: "Material_Composition_List",
    Material_Composition_List_File: "Material_Composition_List_File",
    Material_Composition_List_Format: "Material_Composition_List_Format",
    Material_Composition_List_Format_File: "Material_Composition_List_Format_File",
    mode: "mode",
    Other_1: "Other_1",
    Other_10: "Other_10",
    Other_11: "Other_11",
    Other_12: "Other_12",
    Other_13: "Other_13",
    Other_14: "Other_14",
    Other_15: "Other_15",
    Other_2: "Other_2",
    Other_3: "Other_3",
    Other_4: "Other_4",
    Other_5: "Other_5",
    Other_6: "Other_6",
    Other_7: "Other_7",
    Other_8: "Other_8",
    Other_9: "Other_9",
    P_N: "P_N",
    Package: "Package",
    Package_Cross_Section_Drawings: "Package_Cross_Section_Drawings",
    Package_Reliability: "Package_Reliability",
    Package_Reliability_Site_Flag: "Package_Reliability_Site_Flag",
    partition_key: "Document",
    Product_name: "Product_name",
    Product_Type: "Product_Type",
    Project_No: "3",
    QCS_Flow: "QCS_Flow",
    QCS_Flow_File: "QCS_Flow_File",
    REACH_SVHC_PFOS_etc: "REACH_SVHC_PFOS_etc",
    REACH_SVHC_PFOS_etc_File: "REACH_SVHC_PFOS_etc_File",
    REACH_SVHC_PFOS_etc_Format: "REACH_SVHC_PFOS_etc_Format",
    REACH_SVHC_PFOS_etc_Format_File: "REACH_SVHC_PFOS_etc_Format_File",
    Request_Format: "Request_Format",
    Request_Date: "Request_Date",
    Request_No: "Request_No",
    RoHS_Compliance_Non_Use_Declaration: "RoHS_Compliance_Non_Use_Declaration",
    RoHS_Compliance_Non_Use_Declaration_File: "RoHS_Compliance_Non_Use_Declaration_File",
    RoHS_Compliance_Non_Use_Declaration_Format: "RoHS_Compliance_Non_Use_Declaration_Format",
    RoHS_Compliance_Non_Use_Declaration_Format_File: "RoHS_Compliance_Non_Use_Declaration_Format_File",
    Solderability_MSL_Report: "Solderability_MSL_Report",
    Solderability_MSL_Report_File: "Solderability_MSL_Report_File",
    SonotaKankyoChosaShiryo: "SonotaKankyoChosaShiryo",
    SonotaKankyoChosaShiryo_Format: "SonotaKankyoChosaShiryo_Format",
    status: "A",
    Stick_Tube: "Stick_Tube",
    Stick_Tube_File: "Stick_Tube_File",
    T_R: "T_R",
    T_R_File: "T_R_File",
    Tray: "Tray",
    Tray_File: "Tray_File",
    Tray_Zu: "Tray_Zu",
    Tray_Zu_File: "Tray_Zu_File",
    type: "type",
    Version: "Version",
    Wafer: "Wafer",
    WECJ_Sales_Person: "WECJ_Sales_Person",
    WECJ_TAT: "WECJ_TAT",
    Whisker_Report: "Whisker_Report",
    Whisker_Report_File: "Whisker_Report_File",
    yobi: "yobi"
  }

];



export default HandsonsData_Documents





  //----------------------------------
  // データをマージする
  //----------------------------------
  // const margeData = ( obj_1, obj_2 ) => {
  //   // console.log('obj_1:', obj_1, ', obj_2=', obj_2  )

  //   let obj = JSON.parse(JSON.stringify( obj_1 ));  // ※深いコピーを行う

  //   if( (obj.Uniqu_No                           ==='') || (obj.Uniqu_No                          ===null) ) { obj.Uniqu_No = obj_2.Uniqu_No  }
  //   if( (obj.Part_Name                          ==='') || (obj.Part_Name                         ===null) ) { obj.Part_Name = obj_2.Part_Name  }
  //   if( (obj.Project_No                         ==='') || (obj.Project_No                        ===null) ) { obj.Project_No = obj_2.Project_No  }
  //   if( (obj.Request_No                         ==='') || (obj.Request_No                        ===null) ) { obj.Request_No = obj_2.Request_No  }
  //   if( (obj.CQS_QASR_cansel                    ==='') || (obj.CQS_QASR_cansel                   ===null) ) { obj.CQS_QASR_cansel = obj_2.CQS_QASR_cansel  }
  //   if( (obj.Request_message_received_date      ==='') || (obj.Request_message_received_date     ===null) ) { obj.Request_message_received_date = obj_2.Request_message_received_date  }
  //   if( (obj.Request_Format                     ==='') || (obj.Request_Format                    ===null) ) { obj.Request_Format = obj_2.Request_Format  }
  //   if( (obj.Customer_ID                        ==='') || (obj.Customer_ID                       ===null) ) { obj.Customer_ID = obj_2.Customer_ID  }
  //   if( (obj.Customer_Group                     ==='') || (obj.Customer_Group                    ===null) ) { obj.Customer_Group = obj_2.Customer_Group  }
  //   if( (obj.Customer_atesaki                   ==='') || (obj.Customer_atesaki                  ===null) ) { obj.Customer_atesaki = obj_2.Customer_atesaki  }
  //   if( (obj.Group_Division_or_End_Customer     ==='') || (obj.Group_Division_or_End_Customer    ===null) ) { obj.Group_Division_or_End_Customer = obj_2.Group_Division_or_End_Customer  }
  //   if( (obj.Disti_ID                           ==='') || (obj.Disti_ID                          ===null) ) { obj.Disti_ID = obj_2.Disti_ID  }
  //   if( (obj.Disti_Name                         ==='') || (obj.Disti_Name                        ===null) ) { obj.Disti_Name = obj_2.Disti_Name  }
  //   if( (obj.Disti_PIC                          ==='') || (obj.Disti_PIC                         ===null) ) { obj.Disti_PIC = obj_2.Disti_PIC  }
  //   if( (obj.Disti_Email                        ==='') || (obj.Disti_Email                       ===null) ) { obj.Disti_Email = obj_2.Disti_Email  }
  //   if( (obj.WECJ_Sales_Person                  ==='') || (obj.WECJ_Sales_Person                 ===null) ) { obj.WECJ_Sales_Person = obj_2.WECJ_Sales_Person  }
  //   if( (obj.JQ_Person                          ==='') || (obj.JQ_Person                         ===null) ) { obj.JQ_Person = obj_2.JQ_Person  }
  //   if( (obj.Product_Type                       ==='') || (obj.Product_Type                      ===null) ) { obj.Product_Type = obj_2.Product_Type  }
  //   if( (obj.Version                            ==='') || (obj.Version                           ===null) ) { obj.Version = obj_2.Version  }
  //   if( (obj.P_N                                ==='') || (obj.P_N                               ===null) ) { obj.P_N = obj_2.P_N  }
  //   if( (obj.Product_List                       ==='') || (obj.Product_List                      ===null) ) { obj.Product_List = obj_2.Product_List  }
  //   if( (obj.Customer_P_N                       ==='') || (obj.Customer_P_N                      ===null) ) { obj.Customer_P_N = obj_2.Customer_P_N  }
  //   if( (obj.Customer_DS_Application_Remarks    ==='') || (obj.Customer_DS_Application_Remarks   ===null) ) { obj.Customer_DS_Application_Remarks = obj_2.Customer_DS_Application_Remarks  }
  //   if( (obj.Package                            ==='') || (obj.Package                           ===null) ) { obj.Package = obj_2.Package  }
  //   if( (obj.Product_name                       ==='') || (obj.Product_name                      ===null) ) { obj.Product_name = obj_2.Product_name  }
  //   if( (obj.Die_Version                        ==='') || (obj.Die_Version                       ===null) ) { obj.Die_Version = obj_2.Die_Version  }
  //   if( (obj.Application_Code                   ==='') || (obj.Application_Code                  ===null) ) { obj.Application_Code = obj_2.Application_Code  }
  //   if( (obj.Application_Category               ==='') || (obj.Application_Category              ===null) ) { obj.Application_Category = obj_2.Application_Category  }
  //   if( (obj.Application_Category_1             ==='') || (obj.Application_Category_1            ===null) ) { obj.Application_Category_1 = obj_2.Application_Category_1  }
  //   if( (obj.Application_Category_2             ==='') || (obj.Application_Category_2            ===null) ) { obj.Application_Category_2 = obj_2.Application_Category_2  }
  //   if( (obj.HQ_Receipt_Date                    ==='') || (obj.HQ_Receipt_Date                   ===null) ) { obj.HQ_Receipt_Date = obj_2.HQ_Receipt_Date  }
  //   if( (obj.HQ_Request_Date                    ==='') || (obj.HQ_Request_Date                   ===null) ) { obj.HQ_Request_Date = obj_2.HQ_Request_Date  }
  //   if( (obj.HQ_Request_TAT                     ==='') || (obj.HQ_Request_TAT                    ===null) ) { obj.HQ_Request_TAT = obj_2.HQ_Request_TAT  }
  //   if( (obj.HQ_Request_Actual_Closed_TAT       ==='') || (obj.HQ_Request_Actual_Closed_TAT      ===null) ) { obj.HQ_Request_Actual_Closed_TAT = obj_2.HQ_Request_Actual_Closed_TAT  }
  //   if( (obj.HQ_Actual_Receipt_Date             ==='') || (obj.HQ_Actual_Receipt_Date            ===null) ) { obj.HQ_Actual_Receipt_Date = obj_2.HQ_Actual_Receipt_Date  }
  //   if( (obj.Due_Date                           ==='') || (obj.Due_Date                          ===null) ) { obj.Due_Date = obj_2.Due_Date  }
  //   if( (obj.Density                            ==='') || (obj.Density                           ===null) ) { obj.Density = obj_2.Density  }
  //   if( (obj.Existing_DS_P_J_DS                 ==='') || (obj.Existing_DS_P_J_DS                ===null) ) { obj.Existing_DS_P_J_DS = obj_2.Existing_DS_P_J_DS  }
  //   if( (obj.Issued_Final                       ==='') || (obj.Issued_Final                      ===null) ) { obj.Issued_Final = obj_2.Issued_Final   }
  //   if( (obj.ALL_TAT                            ==='') || (obj.ALL_TAT                           ===null) ) { obj.ALL_TAT = obj_2.ALL_TAT  }
  //   if( (obj.ALL_TAT_CQS_ari_WECJ_WEC_holiday   ==='') || (obj.ALL_TAT_CQS_ari_WECJ_WEC_holiday  ===null) ) { obj.ALL_TAT_CQS_ari_WECJ_WEC_holiday = obj_2.ALL_TAT_CQS_ari_WECJ_WEC_holiday  }
  //   if( (obj.ALL_TAT_CQS_nashi_WECJ_holiday     ==='') || (obj.ALL_TAT_CQS_nashi_WECJ_holiday    ===null) ) { obj.ALL_TAT_CQS_nashi_WECJ_holiday = obj_2.ALL_TAT_CQS_nashi_WECJ_holiday  }
  //   if( (obj.WECJ_TAT                           ==='') || (obj.WECJ_TAT                          ===null) ) { obj.WECJ_TAT = obj_2.WECJ_TAT  }
  //   if( (obj.Delay_from_CRD                     ==='') || (obj.Delay_from_CRD                    ===null) ) { obj.Delay_from_CRD = obj_2.Delay_from_CRD  }
  //   if( (obj.Irai_bunrui                        ==='') || (obj.Irai_bunrui                       ===null) ) { obj.Irai_bunrui = obj_2.Irai_bunrui  }
  //   if( (obj.Hyoushi_HoukokusyoHenkyaku         ==='') || (obj.Hyoushi_HoukokusyoHenkyaku        ===null) ) { obj.Hyoushi_HoukokusyoHenkyaku = obj_2.Hyoushi_HoukokusyoHenkyaku  }
  //   if( (obj.Hyoushi                            ==='') || (obj.Hyoushi                           ===null) ) { obj.Hyoushi = obj_2.Hyoushi  }
  //   if( (obj.Hyoushi_File                       ==='') || (obj.Hyoushi_File                      ===null) ) { obj.Hyoushi_File = obj_2.Hyoushi_File  }
  //   if( (obj.Data_Sheet                         ==='') || (obj.Data_Sheet                        ===null) ) { obj.Data_Sheet = obj_2.Data_Sheet  }
  //   if( (obj.Data_Sheet_File                    ==='') || (obj.Data_Sheet_File                   ===null) ) { obj.Data_Sheet_File = obj_2.Data_Sheet_File  }
  //   if( (obj.Marking_Spec                       ==='') || (obj.Marking_Spec                      ===null) ) { obj.Marking_Spec = obj_2.Marking_Spec  }
  //   if( (obj.Marking_Spec_File                  ==='') || (obj.Marking_Spec_File                 ===null) ) { obj.Marking_Spec_File = obj_2.Marking_Spec_File  }
  //   if( (obj.Wafer                              ==='') || (obj.Wafer                             ===null) ) { obj.Wafer = obj_2.Wafer  }
  //   if( (obj.T_R                                ==='') || (obj.T_R                               ===null) ) { obj.T_R = obj_2.T_R  }
  //   if( (obj.T_R_File                           ==='') || (obj.T_R_File                          ===null) ) { obj.T_R_File = obj_2.T_R_File  }
  //   if( (obj.Tray                               ==='') || (obj.Tray                              ===null) ) { obj.Tray = obj_2.Tray  }
  //   if( (obj.Tray_File                          ==='') || (obj.Tray_File                         ===null) ) { obj.Tray_File = obj_2.Tray_File  }
  //   if( (obj.Tray_Zu                            ==='') || (obj.Tray_Zu                           ===null) ) { obj.Tray_Zu = obj_2.Tray_Zu  }
  //   if( (obj.Tray_Zu_File                       ==='') || (obj.Tray_Zu_File                      ===null) ) { obj.Tray_Zu_File = obj_2.Tray_Zu_File  }
  //   if( (obj.Stick_Tube                         ==='') || (obj.Stick_Tube                        ===null) ) { obj.Stick_Tube = obj_2.Stick_Tube  }
  //   if( (obj.Stick_Tube_File                    ==='') || (obj.Stick_Tube_File                   ===null) ) { obj.Stick_Tube_File = obj_2.Stick_Tube_File  }
  //   if( (obj.IR_Reflow_Profile                  ==='') || (obj.IR_Reflow_Profile                 ===null) ) { obj.IR_Reflow_Profile = obj_2.IR_Reflow_Profile  }
  //   if( (obj.IR_Reflow_Profile_File             ==='') || (obj.IR_Reflow_Profile_File            ===null) ) { obj.IR_Reflow_Profile_File = obj_2.IR_Reflow_Profile_File  }
  //   if( (obj.IR_Reflow_Profile_manual           ==='') || (obj.IR_Reflow_Profile_manual          ===null) ) { obj.IR_Reflow_Profile_manual = obj_2.IR_Reflow_Profile_manual  }
  //   if( (obj.IR_Reflow_Profile_manual_File      ==='') || (obj.IR_Reflow_Profile_manual_File     ===null) ) { obj.IR_Reflow_Profile_manual_File = obj_2.IR_Reflow_Profile_manual_File  }
  //   if( (obj.Mail_Agency                        ==='') || (obj.Mail_Agency                       ===null) ) { obj.Mail_Agency = obj_2.Mail_Agency  }
  //   if( (obj.Chip_Reliability                   ==='') || (obj.Chip_Reliability                  ===null) ) { obj.Chip_Reliability = obj_2.Chip_Reliability  }
  //   if( (obj.Chip_Reliability_File              ==='') || (obj.Chip_Reliability_File             ===null) ) { obj.Chip_Reliability_File = obj_2.Chip_Reliability_File  }
  //   if( (obj.Package_Reliability                ==='') || (obj.Package_Reliability               ===null) ) { obj.Package_Reliability = obj_2.Package_Reliability  }
  //   if( (obj.Package_Reliability_File           ==='') || (obj.Package_Reliability_File          ===null) ) { obj.Package_Reliability_File = obj_2.Package_Reliability_File  }
  //   if( (obj.Package_Reliability_Site_Flag      ==='') || (obj.Package_Reliability_Site_Flag     ===null) ) { obj.Package_Reliability_Site_Flag = obj_2.Package_Reliability_Site_Flag  }
  //   if( (obj.Whisker_Report                     ==='') || (obj.Whisker_Report                    ===null) ) { obj.Whisker_Report= obj_2.Whisker_Report  }
  //   if( (obj.Whisker_Report_File                ==='') || (obj.Whisker_Report_File               ===null) ) { obj.Whisker_Report_File = obj_2.Whisker_Report_File  }
  //   if( (obj.Solderability_MSL_Report           ==='') || (obj.Solderability_MSL_Report          ===null) ) { obj.Solderability_MSL_Report = obj_2.Solderability_MSL_Report  }
  //   if( (obj.Solderability_MSL_Report_File      ==='') || (obj.Solderability_MSL_Report_File     ===null) ) { obj.Solderability_MSL_Report_File = obj_2.Solderability_MSL_Report_File  }
  //   if( (obj.QCS_Flow                           ==='') || (obj.QCS_Flow                          ===null) ) { obj.QCS_Flow = obj_2.QCS_Flow  }
  //   if( (obj.QCS_Flow_File                      ==='') || (obj.QCS_Flow_File                     ===null) ) { obj.QCS_Flow_File = obj_2.QCS_Flow_File  }
  //   if( (obj.Manuf_Site                         ==='') || (obj.Manuf_Site                        ===null) ) { obj.Manuf_Site = obj_2.Manuf_Site  }
  //   if( (obj.Manuf_Site_Flag                    ==='') || (obj.Manuf_Site_Flag                   ===null) ) { obj.Manuf_Site_Flag = obj_2.Manuf_Site_Flag  }
  //   if( (obj.Manuf_Site_Shitei                  ==='') || (obj.Manuf_Site_Shitei                 ===null) ) { obj.Manuf_Site_Shitei = obj_2.Manuf_Site_Shitei  }
  //   if( (obj.Manuf_Site_Sony_GP                 ==='') || (obj.Manuf_Site_Sony_GP                ===null) ) { obj.Manuf_Site_Sony_GP = obj_2.Manuf_Site_Sony_GP  }
  //   if( (obj.Assembly_Site                      ==='') || (obj.Assembly_Site                     ===null) ) { obj.Assembly_Site = obj_2.Assembly_Site  }
  //   if( (obj.FT_Site                            ==='') || (obj.FT_Site                           ===null) ) { obj.FT_Site = obj_2.FT_Site  }
  //   if( (obj.Package_Cross_Section_Drawings     ==='') || (obj.Package_Cross_Section_Drawings    ===null) ) { obj.Package_Cross_Section_Drawings = obj_2.Package_Cross_Section_Drawings  }
  //   if( (obj.Package_Cross_Section_Drawings_File==='') || (obj.Package_Cross_Section_Drawings_File===null) ) { obj.Package_Cross_Section_Drawings_File = obj_2.Package_Cross_Section_Drawings_File= '' }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration        ==='') || (obj.RoHS_Compliance_Non_Use_Declaration        ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration = obj_2.RoHS_Compliance_Non_Use_Declaration  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_File   ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_File   ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_File = obj_2.RoHS_Compliance_Non_Use_Declaration_File  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_Format ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_Format ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_Format = obj_2.RoHS_Compliance_Non_Use_Declaration_Format  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_Format_File ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_Format_File ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_Format_File = obj_2.RoHS_Compliance_Non_Use_Declaration_Format_File  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_Link   ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_Link   ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_Link = obj_2.RoHS_Compliance_Non_Use_Declaration_Link  }
  //   if( (obj.REACH_SVHC_PFOS_etc                ==='') || (obj.REACH_SVHC_PFOS_etc               ===null) ) { obj.REACH_SVHC_PFOS_etc = obj_2.REACH_SVHC_PFOS_etc  }
  //   if( (obj.REACH_SVHC_PFOS_etc_File           ==='') || (obj.REACH_SVHC_PFOS_etc_File          ===null) ) { obj.REACH_SVHC_PFOS_etc_File = obj_2.REACH_SVHC_PFOS_etc_File  }
  //   if( (obj.REACH_SVHC_PFOS_etc_Format         ==='') || (obj.REACH_SVHC_PFOS_etc_Format        ===null) ) { obj.REACH_SVHC_PFOS_etc_Format = obj_2.REACH_SVHC_PFOS_etc_Format  }
  //   if( (obj.REACH_SVHC_PFOS_etc_Format_File    ==='') || (obj.REACH_SVHC_PFOS_etc_Format_File   ===null) ) { obj.REACH_SVHC_PFOS_etc_Format_File = obj_2.REACH_SVHC_PFOS_etc_Format_File  }
  //   if( (obj.REACH_SVHC_PFOS_etc_Link           ==='') || (obj.REACH_SVHC_PFOS_etc_Link          ===null) ) { obj.REACH_SVHC_PFOS_etc_Link = obj_2.REACH_SVHC_PFOS_etc_Link  }
  //   if( (obj.Material_Composition_List          ==='') || (obj.Material_Composition_List         ===null) ) { obj.Material_Composition_List = obj_2.Material_Composition_List  }
  //   if( (obj.Material_Composition_List_File     ==='') || (obj.Material_Composition_List_File    ===null) ) { obj.Material_Composition_List_File = obj_2.Material_Composition_List_File  }
  //   if( (obj.Material_Composition_List_Format   ==='') || (obj.Material_Composition_List_Format  ===null) ) { obj.Material_Composition_List_Format = obj_2.Material_Composition_List_Format  }
  //   if( (obj.Material_Composition_List_Format_File==='') || (obj.Material_Composition_List_Format_File===null) ) { obj.Material_Composition_List_Format_File = obj_2.Material_Composition_List_Format_File  }
  //   if( (obj.Material_Composition_List_Link     ==='') || (obj.Material_Composition_List_Link    ===null) ) { obj.Material_Composition_List_Link = obj_2.Material_Composition_List_Link  }
  //   if( (obj.Environmental_Analysis_MSDS        ==='') || (obj.Environmental_Analysis_MSDS       ===null) ) { obj.Environmental_Analysis_MSDS = obj_2.Environmental_Analysis_MSDS  }
  //   if( (obj.Environmental_Analysis_MSDS_File   ==='') || (obj.Environmental_Analysis_MSDS_File  ===null) ) { obj.Environmental_Analysis_MSDS_File = obj_2.Environmental_Analysis_MSDS_File  }
  //   if( (obj.Other_reliability_report_File      ==='') || (obj.Other_reliability_report_File     ===null) ) { obj.Other_reliability_report_File = obj_2.Other_reliability_report_File  }
  //   if( (obj.ICP_Analysis_Data                  ==='') || (obj.ICP_Analysis_Data                 ===null) ) { obj.ICP_Analysis_Data = obj_2.ICP_Analysis_Data  }
  //   if( (obj.ICP_Analysis_Data_File             ==='') || (obj.ICP_Analysis_Data_File            ===null) ) { obj.ICP_Analysis_Data_File = obj_2.ICP_Analysis_Data_File  }
  //   if( (obj.JAMP_AIS                           ==='') || (obj.JAMP_AIS                          ===null) ) { obj.JAMP_AIS = obj_2.JAMP_AIS  }
  //   if( (obj.JAMP_AIS_File                      ==='') || (obj.JAMP_AIS_File                     ===null) ) { obj.JAMP_AIS_File = obj_2.JAMP_AIS_File  }
  //   if( (obj.JAMP_AIS_WECJ_Day                  ==='') || (obj.JAMP_AIS_WECJ_Day                 ===null) ) { obj.JAMP_AIS_WECJ_Day = obj_2.JAMP_AIS_WECJ_Day  }
  //   if( (obj.JAMP_AIS_Dist_Day                  ==='') || (obj.JAMP_AIS_Dist_Day                 ===null) ) { obj.JAMP_AIS_Dist_Day = obj_2.JAMP_AIS_Dist_Day  }
  //   if( (obj.JAMP_AIS_Version                   ==='') || (obj.JAMP_AIS_Version                  ===null) ) { obj.JAMP_AIS_Version = obj_2.JAMP_AIS_Version  }
  //   if( (obj.chemSHERPA                         ==='') || (obj.chemSHERPA                        ===null) ) { obj.chemSHERPA = obj_2.chemSHERPA  }
  //   if( (obj.chemSHERPA_File                    ==='') || (obj.chemSHERPA_File                   ===null) ) { obj.chemSHERPA_File = obj_2.chemSHERPA_File  }
  //   if( (obj.chemSHERPA_WECJ_Day                ==='') || (obj.chemSHERPA_WECJ_Day               ===null) ) { obj.chemSHERPA_WECJ_Day = obj_2.chemSHERPA_WECJ_Day  }
  //   if( (obj.chemSHERPA_Dist_Day                ==='') || (obj.chemSHERPA_Dist_Day               ===null) ) { obj.chemSHERPA_Dist_Day = obj_2.chemSHERPA_Dist_Day  }
  //   if( (obj.chemSHERPA_Version                 ==='') || (obj.chemSHERPA_Version                ===null) ) { obj.chemSHERPA_Version = obj_2.chemSHERPA_Version  }
  //   if( (obj.JAMA                               ==='') || (obj.JAMA                              ===null) ) { obj.JAMA = obj_2.JAMA  }
  //   if( (obj.JAMA_File                          ==='') || (obj.JAMA_File                         ===null) ) { obj.JAMA_File = obj_2.JAMA_File  }
  //   if( (obj.JAMA_WECJ_Day                      ==='') || (obj.JAMA_WECJ_Day                     ===null) ) { obj.JAMA_WECJ_Day = obj_2.JAMA_WECJ_Day  }
  //   if( (obj.JAMA_Dist_Day                      ==='') || (obj.JAMA_Dist_Day                     ===null) ) { obj.JAMA_Dist_Day = obj_2.JAMA_Dist_Day  }
  //   if( (obj.JAMA_Version                       ==='') || (obj.JAMA_Version                      ===null) ) { obj.JAMA_Version = obj_2.JAMA_Version  }
  //   if( (obj.IMDS                               ==='') || (obj.IMDS                              ===null) ) { obj.IMDS = obj_2.IMDS  }
  //   if( (obj.IMDS_File                          ==='') || (obj.IMDS_File                         ===null) ) { obj.IMDS_File = obj_2.IMDS_File  }
  //   if( (obj.IMDS_WECJ_Day                      ==='') || (obj.IMDS_WECJ_Day                     ===null) ) { obj.IMDS_WECJ_Day = obj_2.IMDS_WECJ_Day  }
  //   if( (obj.IMDS_Dist_Day                      ==='') || (obj.IMDS_Dist_Day                     ===null) ) { obj.IMDS_Dist_Day = obj_2.IMDS_Dist_Day  }
  //   if( (obj.IMDS_Version                       ==='') || (obj.IMDS_Version                      ===null) ) { obj.IMDS_Version = obj_2.IMDS_Version  }
  //   if( (obj.EICC_CMRT                          ==='') || (obj.EICC_CMRT                         ===null) ) { obj.EICC_CMRT = obj_2.EICC_CMRT  }
  //   if( (obj.EICC_CMRT_File                     ==='') || (obj.EICC_CMRT_File                    ===null) ) { obj.EICC_CMRT_File = obj_2.EICC_CMRT_File  }
  //   if( (obj.EICC_CMRT_WECJ_Day                 ==='') || (obj.EICC_CMRT_WECJ_Day                ===null) ) { obj.EICC_CMRT_WECJ_Day = obj_2.EICC_CMRT_WECJ_Day  }
  //   if( (obj.EICC_CMRT_Dist_Day                 ==='') || (obj.EICC_CMRT_Dist_Day                ===null) ) { obj.EICC_CMRT_Dist_Day = obj_2.EICC_CMRT_Dist_Day  }
  //   if( (obj.EICC_CMRT_Version                  ==='') || (obj.EICC_CMRT_Version                 ===null) ) { obj.EICC_CMRT_Version = obj_2.EICC_CMRT_Version   }
  //   if( (obj.SonotaKankyoChosaShiryo            ==='') || (obj.SonotaKankyoChosaShiryo           ===null) ) { obj.SonotaKankyoChosaShiryo = obj_2.SonotaKankyoChosaShiryo  }
  //   if( (obj.SonotaKankyoChosaShiryo_Specify    ==='') || (obj.SonotaKankyoChosaShiryo_Specify   ===null) ) { obj.SonotaKankyoChosaShiryo_Specify = obj_2.SonotaKankyoChosaShiryo_Specify  }
  //   if( (obj.SonotaKankyoChosaShiryo_Format     ==='') || (obj.SonotaKankyoChosaShiryo_Format    ===null) ) { obj.SonotaKankyoChosaShiryo_Format = obj_2.SonotaKankyoChosaShiryo_Format  }
  //   if( (obj.SonotaKankyoChosaShiryo_Format_File==='') || (obj.SonotaKankyoChosaShiryo_Format_File===null) ) { obj.SonotaKankyoChosaShiryo_Format_File = obj_2.SonotaKankyoChosaShiryo_Format_File  }
  //   if( (obj.SonotaKankyoChosaShiryo_Link       ==='') || (obj.SonotaKankyoChosaShiryo_Link      ===null) ) { obj.SonotaKankyoChosaShiryo_Link = obj_2.SonotaKankyoChosaShiryo_Link  }
  //   if( (obj.FunsouKoubutu_CRT                  ==='') || (obj.FunsouKoubutu_CRT                 ===null) ) { obj.FunsouKoubutu_CRT = obj_2.FunsouKoubutu_CRT  }
  //   if( (obj.FunsouKoubutu_CRT_File             ==='') || (obj.FunsouKoubutu_CRT_File            ===null) ) { obj.FunsouKoubutu_CRT_File = obj_2.FunsouKoubutu_CRT_File  }
  //   if( (obj.FunsouKoubutu_CRT_WECJ_Day         ==='') || (obj.FunsouKoubutu_CRT_WECJ_Day        ===null) ) { obj.FunsouKoubutu_CRT_WECJ_Day = obj_2.FunsouKoubutu_CRT_WECJ_Day  }
  //   if( (obj.FunsouKoubutu_CRT_Dist_Day         ==='') || (obj.FunsouKoubutu_CRT_Dist_Day        ===null) ) { obj.FunsouKoubutu_CRT_Dist_Day = obj_2.FunsouKoubutu_CRT_Dist_Day  }
  //   if( (obj.FunsouKoubutu_CRT_Version          ==='') || (obj.FunsouKoubutu_CRT_Version         ===null) ) { obj.FunsouKoubutu_CRT_Version = obj_2.FunsouKoubutu_CRT_Version  }
  //   if( (obj.Other_1                            ==='') || (obj.Other_1                           ===null) ) { obj.Other_1 = obj_2.Other_1  }
  //   if( (obj.Other_2                            ==='') || (obj.Other_2                           ===null) ) { obj.Other_2 = obj_2.Other_2  }
  //   if( (obj.Other_3                            ==='') || (obj.Other_3                           ===null) ) { obj.Other_3 = obj_2.Other_3  }
  //   if( (obj.Other_4                            ==='') || (obj.Other_4                           ===null) ) { obj.Other_4 = obj_2.Other_4  }
  //   if( (obj.Other_5                            ==='') || (obj.Other_5                           ===null) ) { obj.Other_5 = obj_2.Other_5  }
  //   if( (obj.Other_6                            ==='') || (obj.Other_6                           ===null) ) { obj.Other_6 = obj_2.Other_6  }
  //   if( (obj.Other_7                            ==='') || (obj.Other_7                           ===null) ) { obj.Other_7 = obj_2.Other_7  }
  //   if( (obj.Other_8                            ==='') || (obj.Other_8                           ===null) ) { obj.Other_8 = obj_2.Other_8  }
  //   if( (obj.Other_9                            ==='') || (obj.Other_9                           ===null) ) { obj.Other_9 = obj_2.Other_9  }
  //   if( (obj.Other_10                           ==='') || (obj.Other_10                          ===null) ) { obj.Other_10 = obj_2.Other_10  }
  //   if( (obj.Other_11                           ==='') || (obj.Other_11                          ===null) ) { obj.Other_11 = obj_2.Other_11  }
  //   if( (obj.Other_12                           ==='') || (obj.Other_12                          ===null) ) { obj.Other_12 = obj_2.Other_12  }
  //   if( (obj.Other_13                           ==='') || (obj.Other_13                          ===null) ) { obj.Other_13 = obj_2.Other_13  }
  //   if( (obj.Other_14                           ==='') || (obj.Other_14                          ===null) ) { obj.Other_14 = obj_2.Other_14  }
  //   if( (obj.Other_15                           ==='') || (obj.Other_15                          ===null) ) { obj.Other_15 = obj_2.Other_15  }
  //   if( (obj.Comment                            ==='') || (obj.Comment                           ===null) ) { obj.Comment = obj_2.Comment  }
  //   if( (obj.WECJ_Remark                        ==='') || (obj.WECJ_Remark                       ===null) ) { obj.WECJ_Remark = obj_2.WECJ_Remark  }
  //   if( (obj.EndCustomer                        ==='') || (obj.EndCustomer                       ===null) ) { obj.EndCustomer = obj_2.EndCustomer  }

  //   // 追加
  //   if( (obj.Chip_Reliability_WECJ_Day          ==='') || (obj.Chip_Reliability_WECJ_Day         ===null) ) { obj.Chip_Reliability_WECJ_Day = obj_2.Chip_Reliability_WECJ_Day  }
  //   if( (obj.Chip_Reliability_Dist_Day          ==='') || (obj.Chip_Reliability_Dist_Day         ===null) ) { obj.Chip_Reliability_Dist_Day = obj_2.Chip_Reliability_Dist_Day  }
  //   if( (obj.Data_Sheet_WECJ_Day                ==='') || (obj.Data_Sheet_WECJ_Day               ===null) ) { obj.Data_Sheet_WECJ_Day = obj_2.Data_Sheet_WECJ_Day  }
  //   if( (obj.Data_Sheet_Dist_Day                ==='') || (obj.Data_Sheet_Dist_Day               ===null) ) { obj.Data_Sheet_Dist_Day = obj_2.Data_Sheet_Dist_Day  }
  //   if( (obj.Environmental_Analysis_MSDS_WECJ_Day ==='') || (obj.Environmental_Analysis_MSDS_WECJ_Day ===null) ) { obj.Environmental_Analysis_MSDS_WECJ_Day = obj_2.Environmental_Analysis_MSDS_WECJ_Day  }
  //   if( (obj.Environmental_Analysis_MSDS_Dist_Day ==='') || (obj.Environmental_Analysis_MSDS_Dist_Day ===null) ) { obj.Environmental_Analysis_MSDS_Dist_Day = obj_2.Environmental_Analysis_MSDS_Dist_Day  }
  //   if( (obj.Hyoushi_WECJ_Day                   ==='') || (obj.Hyoushi_WECJ_Day                  ===null) ) { obj.Hyoushi_WECJ_Day = obj_2.Hyoushi_WECJ_Day  }
  //   if( (obj.Hyoushi_Dist_Day                   ==='') || (obj.Hyoushi_Dist_Day                  ===null) ) { obj.Hyoushi_Dist_Day = obj_2.Hyoushi_Dist_Day  }
  //   if( (obj.ICP_Analysis_Data_WECJ_Day         ==='') || (obj.ICP_Analysis_Data_WECJ_Day        ===null) ) { obj.ICP_Analysis_Data_WECJ_Day = obj_2.ICP_Analysis_Data_WECJ_Day  }
  //   if( (obj.ICP_Analysis_Data_Dist_Day         ==='') || (obj.ICP_Analysis_Data_Dist_Day        ===null) ) { obj.ICP_Analysis_Data_Dist_Day = obj_2.ICP_Analysis_Data_Dist_Day  }
  //   if( (obj.IR_Reflow_Profile_WECJ_Day         ==='') || (obj.IR_Reflow_Profile_WECJ_Day        ===null) ) { obj.IR_Reflow_Profile_WECJ_Day = obj_2.IR_Reflow_Profile_WECJ_Day  }
  //   if( (obj.IR_Reflow_Profile_Dist_Day         ==='') || (obj.IR_Reflow_Profile_Dist_Day        ===null) ) { obj.IR_Reflow_Profile_Dist_Day = obj_2.IR_Reflow_Profile_Dist_Day  }
  //   if( (obj.IR_Reflow_Profile_manual_WECJ_Day  ==='') || (obj.IR_Reflow_Profile_manual_WECJ_Day ===null) ) { obj.IR_Reflow_Profile_manual_WECJ_Day = obj_2.IR_Reflow_Profile_manual_WECJ_Day  }
  //   if( (obj.IR_Reflow_Profile_manual_Dist_Day  ==='') || (obj.IR_Reflow_Profile_manual_Dist_Day ===null) ) { obj.IR_Reflow_Profile_manual_Dist_Day = obj_2.IR_Reflow_Profile_manual_Dist_Day  }
  //   if( (obj.Manuf_Site_File                    ==='') || (obj.Manuf_Site_File                   ===null) ) { obj.Manuf_Site_File = obj_2.Manuf_Site_File  }
  //   if( (obj.Manuf_Site_WECJ_Day                ==='') || (obj.Manuf_Site_WECJ_Day               ===null) ) { obj.Manuf_Site_WECJ_Day = obj_2.Manuf_Site_WECJ_Day  }
  //   if( (obj.Manuf_Site_Dist_Day                ==='') || (obj.Manuf_Site_Dist_Day               ===null) ) { obj.Manuf_Site_Dist_Day = obj_2.Manuf_Site_Dist_Day  }
  //   if( (obj.Marking_Spec_WECJ_Day              ==='') || (obj.Marking_Spec_WECJ_Day             ===null) ) { obj.Marking_Spec_WECJ_Day = obj_2.Marking_Spec_WECJ_Day  }
  //   if( (obj.Marking_Spec_Dist_Day              ==='') || (obj.Marking_Spec_Dist_Day             ===null) ) { obj.Marking_Spec_Dist_Day = obj_2.Marking_Spec_Dist_Day  }
  //   if( (obj.Material_Composition_List_WECJ_Day          ==='') || (obj.Material_Composition_List_WECJ_Day         ===null) ) { obj.Material_Composition_List_WECJ_Day = obj_2.Material_Composition_List_WECJ_Day  }
  //   if( (obj.Material_Composition_List_Dist_Day          ==='') || (obj.Material_Composition_List_Dist_Day         ===null) ) { obj.Material_Composition_List_Dist_Day = obj_2.Material_Composition_List_Dist_Day  }
  //   if( (obj.Material_Composition_List_Format_WECJ_Day   ==='') || (obj.Material_Composition_List_Format_WECJ_Day  ===null) ) { obj.Material_Composition_List_Format_WECJ_Day = obj_2.Material_Composition_List_Format_WECJ_Day  }
  //   if( (obj.Material_Composition_List_Format_Dist_Day   ==='') || (obj.Material_Composition_List_Format_Dist_Day  ===null) ) { obj.Material_Composition_List_Format_Dist_Day = obj_2.Material_Composition_List_Format_Dist_Day  }
  //   if( (obj.Material_Composition_List_Format_WECJ_File  ==='') || (obj.Material_Composition_List_Format_WECJ_File ===null) ) { obj.Material_Composition_List_Format_WECJ_File = obj_2.Material_Composition_List_Format_WECJ_File  }
  //   if( (obj.Package_Cross_Section_Drawings_WECJ_Day     ==='') || (obj.Package_Cross_Section_Drawings_WECJ_Day    ===null) ) { obj.Package_Cross_Section_Drawings_WECJ_Day = obj_2.Package_Cross_Section_Drawings_WECJ_Day  }
  //   if( (obj.Package_Reliability_WECJ_Day                ==='') || (obj.Package_Reliability_WECJ_Day               ===null) ) { obj.Package_Reliability_WECJ_Day = obj_2.Package_Reliability_WECJ_Day  }
  //   if( (obj.Package_Reliability_Dist_Day                ==='') || (obj.Package_Reliability_Dist_Day               ===null) ) { obj.Package_Reliability_Dist_Day = obj_2.Package_Reliability_Dist_Day  }
  //   if( (obj.Product_List_WECJ_Day                       ==='') || (obj.Product_List_WECJ_Day                      ===null) ) { obj.Product_List_WECJ_Day = obj_2.Product_List_WECJ_Day  }
  //   if( (obj.Product_List_Dist_Day                       ==='') || (obj.Product_List_Dist_Day                      ===null) ) { obj.Product_List_Dist_Day = obj_2.Product_List_Dist_Day  }
  //   if( (obj.QCS_Flow_WECJ_Day                           ==='') || (obj.QCS_Flow_WECJ_Day                          ===null) ) { obj.QCS_Flow_WECJ_Day = obj_2.QCS_Flow_WECJ_Day  }
  //   if( (obj.QCS_Flow_Dist_Day                           ==='') || (obj.QCS_Flow_Dist_Day                          ===null) ) { obj.QCS_Flow_Dist_Day = obj_2.QCS_Flow_Dist_Day  }
  //   if( (obj.REACH_SVHC_PFOS_etc_WECJ_Day                ==='') || (obj.REACH_SVHC_PFOS_etc_WECJ_Day               ===null) ) { obj.REACH_SVHC_PFOS_etc_WECJ_Day = obj_2.REACH_SVHC_PFOS_etc_WECJ_Day  }
  //   if( (obj.REACH_SVHC_PFOS_etc_Dist_Day                ==='') || (obj.REACH_SVHC_PFOS_etc_Dist_Day               ===null) ) { obj.REACH_SVHC_PFOS_etc_Dist_Day = obj_2.REACH_SVHC_PFOS_etc_Dist_Day  }
  //   if( (obj.REACH_SVHC_PFOS_etc_Format_WECJ_Day         ==='') || (obj.REACH_SVHC_PFOS_etc_Format_WECJ_Day        ===null) ) { obj.REACH_SVHC_PFOS_etc_Format_WECJ_Day = obj_2.REACH_SVHC_PFOS_etc_Format_WECJ_Day  }
  //   if( (obj.REACH_SVHC_PFOS_etc_Format_Dist_Day         ==='') || (obj.REACH_SVHC_PFOS_etc_Format_Dist_Day        ===null) ) { obj.REACH_SVHC_PFOS_etc_Format_Dist_Day = obj_2.REACH_SVHC_PFOS_etc_Format_Dist_Day  }
  //   if( (obj.REACH_SVHC_PFOS_etc_Format_WECJ_File        ==='') || (obj.REACH_SVHC_PFOS_etc_Format_WECJ_File       ===null) ) { obj.REACH_SVHC_PFOS_etc_Format_WECJ_File = obj_2.REACH_SVHC_PFOS_etc_Format_WECJ_File  }
  //   if( (obj.Request_Date                                ==='') || (obj.Request_Date                               ===null) ) { obj.Request_Date = obj_2.Request_Date  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_WECJ_Day           ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_WECJ_Day          ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_WECJ_Day = obj_2.RoHS_Compliance_Non_Use_Declaration_WECJ_Day  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_Dist_Day           ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_Dist_Day          ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_Dist_Day = obj_2.RoHS_Compliance_Non_Use_Declaration_Dist_Day  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_Day    ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_Day   ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_Day = obj_2.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_Day  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_Format_Dist_Day    ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_Format_Dist_Day   ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_Format_Dist_Day = obj_2.RoHS_Compliance_Non_Use_Declaration_Format_Dist_Day  }
  //   if( (obj.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_File   ==='') || (obj.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_File  ===null) ) { obj.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_File = obj_2.RoHS_Compliance_Non_Use_Declaration_Format_WECJ_File  }
  //   if( (obj.Solderability_MSL_Report_WECJ_Day           ==='') || (obj.Solderability_MSL_Report_WECJ_Day          ===null) ) { obj.Solderability_MSL_Report_WECJ_Day = obj_2.Solderability_MSL_Report_WECJ_Day  }
  //   if( (obj.Solderability_MSL_Report_Dist_Day           ==='') || (obj.Solderability_MSL_Report_Dist_Day          ===null) ) { obj.Solderability_MSL_Report_Dist_Day = obj_2.Solderability_MSL_Report_Dist_Day  }
  //   if( (obj.SonotaKankyoChosaShiryo_Format_WECJ_Day     ==='') || (obj.SonotaKankyoChosaShiryo_Format_WECJ_Day    ===null) ) { obj.SonotaKankyoChosaShiryo_Format_WECJ_Day = obj_2.SonotaKankyoChosaShiryo_Format_WECJ_Day  }
  //   if( (obj.SonotaKankyoChosaShiryo_Format_Dist_Day     ==='') || (obj.SonotaKankyoChosaShiryo_Format_Dist_Day    ===null) ) { obj.SonotaKankyoChosaShiryo_Format_Dist_Day = obj_2.SonotaKankyoChosaShiryo_Format_Dist_Day  }
  //   if( (obj.SonotaKankyoChosaShiryo_Format_WECJ_File    ==='') || (obj.SonotaKankyoChosaShiryo_Format_WECJ_File   ===null) ) { obj.SonotaKankyoChosaShiryo_Format_WECJ_File = obj_2.SonotaKankyoChosaShiryo_Format_WECJ_File  }
  //   if( (obj.Other_reliability_report_WECJ_Day           ==='') || (obj.Other_reliability_report_WECJ_Day          ===null) ) { obj.Other_reliability_report_WECJ_Day = obj_2.Other_reliability_report_WECJ_Day  }
  //   if( (obj.Other_reliability_report_Dist_Day           ==='') || (obj.Other_reliability_report_Dist_Day          ===null) ) { obj.Other_reliability_report_Dist_Day = obj_2.Other_reliability_report_Dist_Day  }
  //   if( (obj.Other_reliability_report_WECJ_File          ==='') || (obj.Other_reliability_report_WECJ_File         ===null) ) { obj.Other_reliability_report_WECJ_File = obj_2.Other_reliability_report_WECJ_File  }
  //   if( (obj.Stick_Tube_WECJ_Day                         ==='') || (obj.Stick_Tube_WECJ_Day                        ===null) ) { obj.Stick_Tube_WECJ_Day = obj_2.Stick_Tube_WECJ_Day  }
  //   if( (obj.Stick_Tube_Dist_Day                         ==='') || (obj.Stick_Tube_Dist_Day                        ===null) ) { obj.Stick_Tube_Dist_Day = obj_2.Stick_Tube_Dist_Day  }
  //   if( (obj.T_R_WECJ_Day                                ==='') || (obj.T_R_WECJ_Day                               ===null) ) { obj.T_R_WECJ_Day = obj_2.T_R_WECJ_Day  }
  //   if( (obj.T_R_Dist_Day                                ==='') || (obj.T_R_Dist_Day                               ===null) ) { obj.T_R_Dist_Day = obj_2.T_R_Dist_Day  }
  //   if( (obj.Tray_WECJ_Day                               ==='') || (obj.Tray_WECJ_Day                              ===null) ) { obj.Tray_WECJ_Day = obj_2.Tray_WECJ_Day  }
  //   if( (obj.Tray_Dist_Day                               ==='') || (obj.Tray_Dist_Day                              ===null) ) { obj.Tray_Dist_Day = obj_2.Tray_Dist_Day  }
  //   if( (obj.Tray_Zu_WECJ_Day                            ==='') || (obj.Tray_Zu_WECJ_Day                           ===null) ) { obj.Tray_Zu_WECJ_Day = obj_2.Tray_Zu_WECJ_Day  }
  //   if( (obj.Tray_Zu_Dist_Day                            ==='') || (obj.Tray_Zu_Dist_Day                           ===null) ) { obj.Tray_Zu_Dist_Day = obj_2.Tray_Zu_Dist_Day  }
  //   if( (obj.Whisker_Report_WECJ_Day                     ==='') || (obj.Whisker_Report_WECJ_Day                    ===null) ) { obj.Whisker_Report_WECJ_Day = obj_2.Whisker_Report_WECJ_Day  }
  //   if( (obj.Whisker_Report_Dist_Day                     ==='') || (obj.Whisker_Report_Dist_Day                    ===null) ) { obj.Whisker_Report_Dist_Day = obj_2.Whisker_Report_Dist_Day  }


  //   return obj
  // }


