const HandsonsData_Logs = {}

HandsonsData_Logs.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
}



HandsonsData_Logs.colHeaders_Name = [
  '日時',
  '種別ID',
  '種別名',
  'Target',
  'type',
  'Value',
  // '変更カラム',
  // '変更前の値',
  // '変更後の値',
  'ユーザー'
];

HandsonsData_Logs.columns_Data = [
  { data: 'Date', readOnly: true },
  { data: 'API_P_KEY_ID' },
  { data: 'API_P_KEY' },
  { data: 'target' },
  { data: 'type' },
  { data: 'Value' },
  // { data: 'Column' },
  // { data: 'FormerValue' },
  // { data: 'LatterValue' },
  { data: 'User' }
];


HandsonsData_Logs.data = [
];

HandsonsData_Logs.sampledata = [
  {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",

    Date: "",
    ControlNo: "",
    API_P_KEY_ID: "",
    API_P_KEY: "",
    target: "",
    Value: "",
    Column: "",
    FormerValue: "",
    LatterValue: "",
    User: "",
  },
];




HandsonsData_Logs.testdata = [
  {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'020/05/21 09:44:29',
    ControlNo:'-17769',
    API_P_KEY_ID:'10',
    API_P_KEY:'Document',
    Target: "",
    Value: "",
    Column:'other_12',
    FormerValue:'',
    LatterValue:'12',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43972.4058912037',
    ControlNo:'20',
    API_P_KEY_ID:'10',
    API_P_KEY:'Document',
    Target: "",
    Value: "",
    Column:'other_5',
    FormerValue:'',
    LatterValue:'115',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",      
    Date:'43972.4058912037',
    ControlNo:'-17769',
    API_P_KEY_ID:'10',
    API_P_KEY:'Document',
    Target: "",
    Value: "",
    Column:'control_no',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43972.4058912037',
    ControlNo:'-17769',
    API_P_KEY_ID:'20',
    API_P_KEY:'CFAR',
    Target: "",
    Value: "",
    Column:'request_message_received_date',
    FormerValue:'',
    LatterValue:'43972',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43972.4058912037',
    ControlNo:'-17769',
    API_P_KEY_ID:'20',
    API_P_KEY:'CFAR',
    Target: "",
    Value: "",
    Column:'システムID',
    FormerValue:'',
    LatterValue:'17769',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43972.4046643519',
    ControlNo:'-17769',
    API_P_KEY_ID:'70',
    API_P_KEY:'Login',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43969.3969444444',
    ControlNo:'-17765',
    API_P_KEY_ID:'70',
    API_P_KEY:'Login',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43969.3968865741',
    ControlNo:'-17764',
    API_P_KEY_ID:'30',
    API_P_KEY:'User',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43941.4769328704',
    ControlNo:'-17758',
    API_P_KEY_ID:'30',
    API_P_KEY:'User',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43941.4769328704',
    ControlNo:'-17758',
    API_P_KEY_ID:'50',
    API_P_KEY:'Upload',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43941.4769328704',
    ControlNo:'-17758',
    API_P_KEY_ID:'50',
    API_P_KEY:'Upload',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43941.4769328704',
    ControlNo:'-17758',
    API_P_KEY_ID:'60',
    API_P_KEY:'Download',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43941.4769328704',
    ControlNo:'-17758',
    API_P_KEY_ID:'60',
    API_P_KEY:'Download',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43941.4769328704',
    ControlNo:'-17758',
    API_P_KEY_ID:'60',
    API_P_KEY:'Download',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    },
    {
    delete_Flag: "0",
    partition_key: "Logs",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Date:'43941.4769328704',
    ControlNo:'-17758',
    API_P_KEY_ID:'60',
    API_P_KEY:'Download',
    Target: "",
    Value: "",
    Column:'',
    FormerValue:'',
    LatterValue:'',
    User:'Master管理者',
    }

];


export default HandsonsData_Logs
