const HandsonsData_OrderCFAR = {}

HandsonsData_OrderCFAR.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Control_disabled: true,
  Download_disabled: true,
  Button_hidden: false,
  Details_hidden: true,
  Admin_hidden: true,
  System_hidden: true,
  CFAR_TO_FM_Notification_hidden: true,

  Btn_hidden_Old_Load: true,
  Btn_hidden_Save: true,
  Btn_hidden_Apply_Request: true,
  Btn_hidden_Apply_Cancel: true,
  Btn_hidden_Apply_Correct: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval_Cancel: true,
  Btn_hidden_Approval_Request: true,
  Btn_hidden_Approval_DounLoad: true,

  Btn2_hidden_Save: false,
  Btn2_hidden_Apply_Request: false,
  Btn2_hidden_Apply_Cancel: false,
  Btn2_hidden_Apply_Correct: false,
  Btn2_hidden_Remand: false,
  Btn2_hidden_Approval_Cancel: false,
  Btn2_hidden_Approval_Request: false,
  Btn2_hidden_Approval_DounLoad: false,

}



// 2021.06.05 K.Ueoku 列名変更「Sales Manager (WECJ)」→「WECJ Sales」
// 2021.06.05 K.Ueoku 列追加「WECJ Remark」
HandsonsData_OrderCFAR.colHeaders_Name = [
  '削除',
  // 'partition_key', 
  // 'create_time', 
  // 'mode', 
  // 'Request_No', 
  // 'Project_No' ,
  // 'Unique ID',
  // 'No.',
  'Unique No', 
  'Control No',
  'Status', 
  'Distributer ID',
  // 'type', 
  'Request Format#' ,

  'Closed Date',
  'Closed Month',
  'CANCEL',
  'Month',
  'Issued Date',

  'CFAR#',
  'Customer Request',
  'sample#',
  'Memory Type',
  'Part Number',
  'Product Type',

  'Process',
  'Version',
  'Density',
  'Application Code',
  'Application Category',
  'Application Category 1',
  'Application Category 2',
  'Application Remark',
  'Customer ID',
  'Customer',
  'Customer Group',
  'Distributer',

  'Complaint Symptom',
  'Failed Location',
  'Failed Location 2',
  'Ship Out Date',
  'Days from Ship-out to Fail Occurrence',
  'D/C',
  'Lot No.',
  'Total Q\'ty Delivered',
  '# of Bad Parts',
  'Qty.Returned / Packing & VI Case Count',
  '# of Parts Used',
  'Cross Check Done',
  'JQ PIC',
  'Initial Report Issued',
  'Initial Report Issued Link',
  'Initial Report Issued to Customer',
  'Final Report Issued',
  'Final Report Issued Link',
  'Final Report Issued to Customer',
  'Sample arrival Date (to WECHQ)',

  'Ass\'y',
  'FT',
  'TAT (Initial)',
  'TAT (Final)',
  'TAT (Case Close)',
  'TAT (Case Close Final Report~Close)',
  'RESULT',
  'PFA',
  'FA Results',
  'Sample Return Date',

  'Other Report 1（Date）',
  'Other Report 1（Link）',
  'Other Report 1（Category）',
  'Other Report 1 (Remark)',
  'Other Report 2（Date）',
  'Other Report 2（Link）',
  'Other Report 2（Category）',
  'Other Report 2 (Remark)',
  'Other Report 3（Date）',
  'Other Report 3（Link）',
  'Other Report 3（Category）',
  'Other Report 3 (Remark)',
  'Other Report 4（Date）',
  'Other Report 4（Link）',
  'Other Report 4（Category）',
  'Other Report 4 (Remark)',
  'Other Report 5（Date）',
  'Other Report 5（Link）',
  'Other Report 5（Category）',
  'Other Report 5 (Remark)',
  'Other Report 6（Date）',
  'Other Report 6（Link）',
  'Other Report 6（Category）',
  'Other Report 6 (Remark)',
  'Other Report 7（Date）',
  'Other Report 7（Link）',
  'Other Report 7（Category）',
  'Other Report 7 (Remark)',
  'Other Report 8（Date）',
  'Other Report 8（Link）',
  'Other Report 8（Category）',
  'Other Report 8 (Remark)',
  'Other Report 9（Date）',
  'Other Report 9（Link）',
  'Other Report 9（Category）',
  'Other Report 9 (Remark)',
  'Other Report 10（Date）',
  'Other Report 10（Link）',
  'Other Report 10（Category）',
  'Other Report 10 (Remark)',
  'Other Report 11（Date）',
  'Other Report 11（Link）',
  'Other Report 11（Category）',
  'Other Report 11 (Remark)',
  'Other Report 12（Date）',
  'Other Report 12（Link）',
  'Other Report 12（Category）',
  'Other Report 12 (Remark)',
  'Other Report 13（Date）',
  'Other Report 13（Link）',
  'Other Report 13（Category）',
  'Other Report 13 (Remark)',
  'Other Report 14（Date）',
  'Other Report 14（Link）',
  'Other Report 14（Category）',
  'Other Report 14 (Remark)',
  'Other Report 15（Date）',
  'Other Report 15（Link）',
  'Other Report 15（Category）',
  'Other Report 15 (Remark)',
  'Other Report 16（Date）',
  'Other Report 16（Link）',
  'Other Report 16（Category）',
  'Other Report 16 (Remark)',
  'Other Report 17（Date）',
  'Other Report 17（Link）',
  'Other Report 17（Category）',
  'Other Report 17 (Remark)',
  'Other Report 18（Date）',
  'Other Report 18（Link）',
  'Other Report 18（Category）',
  'Other Report 18 (Remark)',
  'Other Report 19（Date）',
  'Other Report 19（Link）',
  'Other Report 19（Category）',
  'Other Report 19 (Remark)',
  'Other Report 20（Date）',
  'Other Report 20（Link）',
  'Other Report 20（Category）',
  'Other Report 20 (Remark)',

  'EFA1: Programmer/Vt',
  'EFA2: ATE/MOSAID 1',
  'EFA3: ATE/MOSAID 2',
  'EFA4: Electrical Failure Mode',
  'EFA5: Layout Tracing: Layer 1',
  'EFA6: Layout Tracing: Layer 2',
  'PFA1: Root Cause Defect',
  'CIP1: Process',
  'CIP2: Test',
  'CIP3: Test Vector',
  'CIP4:  Process CIP Clean D/C',
  'CIP5: Test  CIP Clean D/C',
  'EFA3: ATE/MOSAID 2 Details',
  'SAP Complete Date',
  'Close Date Monitor (Close Date)',
  'TO',
  'Agency Person In Charge',
  'Request Date',
  'P/O No',
  'Customer\'s Report Included',
  'Customer\'s Report (Link)',

  'TEL (Agency)',
  'FAX (Agency)',
  // 'Mail (Agency)',
  'Defect Rate (%)',
  'WECJ FAE Check',
  'WECJ FAE Name',
  'Module Name',

  'Action Requested: Replacement',
  'Action Requested: Credit',
  'Action Requested: Pass/Fail',
  'Action Requested: Further Analysis Report',
  'Action Requested: Others',
  // 'Sales Manager (WECJ)',
  'WECJ Sales',
  'Received by (QA)',
  'QA Receiving Date/Time',
  'WECJ Remark',
  // '作成日' 
  // 'Customer_Defect_Rate',
  // 'Detailed Description of Symptom',
  // 'yobi' 
];



// 2021.06.05 K.Ueoku 「WECJ Sales」（元：Sales Manager）を選択式に変更
// 2021.06.05 K.Ueoku 列追加「WECJ Remark」
HandsonsData_OrderCFAR.columns_Data = [
  {
    data: 'delete_Flag',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  // {data: 'partition_key'}, 
  // {data: 'create_time', readOnly: true}, 
  // {data: 'mode'}, 
  // {data: 'Request_No'}, 
  // {data: 'Project_No'},
  // {data: 'No'},
  {data: 'create_time', readOnly: true },
  {data: 'Uniqu_No', readOnly: false},
  { 
    data: 'status',
    type: 'dropdown',
    source: ['作成中','登録申請中', 'キャンセル申請中', 'キャンセル受付済', '差戻し', '受付済', 'ダウンロード可能' ],
  },
  {data: 'Disti_ID'},
  // {data: 'type'},
  {data: 'Request_Format'},

  {data: 'Closed_Date'},
  {data: 'Closed_Month'},
  {data: 'CANCEL'},
  {data: 'Month'},
  {data: 'Issued_Date'},

  {data: 'CFAR'},
  {data: 'Customer_Request'},
  {data: 'sample'},
  {data: 'Memory_Type'},
  {data: 'Part_Number'},
  {data: 'Product_Type'},

  {data: 'Process'},
  {data: 'Version'},
  {data: 'Density'},
  { 
    data: 'Application_Code',
    type: 'dropdown',
    source: ['a','b','c' ],
  },
  {data: 'Application_Category', readOnly: false },
  {data: 'Application_Category_1', readOnly: false },
  {data: 'Application_Category_2', readOnly: false },
  {data: 'Application_Remark'},
  {data: 'Customer_ID'},
  {data: 'Customer'},
  {data: 'Customer_Group'},
  {data: 'Distributer'},

  {data: 'Detailed_Description_of_Symptom'},
  { 
    data: 'Failed_Location',
    type: 'dropdown',
    source: ['Incoming Quality Control','Qualification','Line Fall Out','0KM','Field Return' ],
  },
  {data: 'Failed_Location_2'},
  {data: 'Ship_Out_Date'},
  {data: 'Days_from_Ship_out_to_Fail_Occurrence'},
  {data: 'D_C'},
  {data: 'Lot_No'},
  {data: 'Total_Qty_Delivered'},
  {data: 'of_Bad_Parts'},
  {data: 'QtyReturned_Packing_VI_Case_Count'},
  {data: 'of_Parts_Used'},
  {
    data: 'Cross_Check_Done',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'JQ_PIC'},
  {data: 'Initial_Report_Issued'},
  {data: 'Initial_Report_Issued_Link'},
  {data: 'Initial_Report_Issued_to_Customer'},
  {data: 'Final_Report_Issued'},
  {data: 'Final_Report_Issued_Link'},
  {data: 'Final_Report_Issued_to_Customer'},
  {data: 'Sample_arrival_Date_to_WECHQ'},

  {data: 'Ass_y'},
  {data: 'FT'},
  {data: 'TAT_Initial'},
  {data: 'TAT_Final'},
  {data: 'TAT_Case_Close'},
  {data: 'TAT_Case_Close_Final_Report_Close'},
  {data: 'RESULT'},
  {data: 'PFA'},
  {data: 'FA_Results'},
  {data: 'Sample_Return_Date'},

  {data: 'Other_Report_1_Date'},
  {data: 'Other_Report_1_Link'},
  {data: 'Other_Report_1_Category'},
  {data: 'Other_Report_1_Remark'},
  {data: 'Other_Report_2_Date'},
  {data: 'Other_Report_2_Link'},
  {data: 'Other_Report_2_Category'},
  {data: 'Other_Report_2_Remark'},
  {data: 'Other_Report_3_Date'},
  {data: 'Other_Report_3_Link'},
  {data: 'Other_Report_3_Category'},
  {data: 'Other_Report_3_Remark'},
  {data: 'Other_Report_4_Date'},
  {data: 'Other_Report_4_Link'},
  {data: 'Other_Report_4_Category'},
  {data: 'Other_Report_4_Remark'},
  {data: 'Other_Report_5_Date'},
  {data: 'Other_Report_5_Link'},
  {data: 'Other_Report_5_Category'},
  {data: 'Other_Report_5_Remark'},
  {data: 'Other_Report_6_Date'},
  {data: 'Other_Report_6_Link'},
  {data: 'Other_Report_6_Category'},
  {data: 'Other_Report_6_Remark'},
  {data: 'Other_Report_7_Date'},
  {data: 'Other_Report_7_Link'},
  {data: 'Other_Report_7_Category'},
  {data: 'Other_Report_7_Remark'},
  {data: 'Other_Report_8_Date'},
  {data: 'Other_Report_8_Link'},
  {data: 'Other_Report_8_Category'},
  {data: 'Other_Report_8_Remark'},
  {data: 'Other_Report_9_Date'},
  {data: 'Other_Report_9_Link'},
  {data: 'Other_Report_9_Category'},
  {data: 'Other_Report_9_Remark'},
  {data: 'Other_Report_10_Date'},
  {data: 'Other_Report_10_Link'},
  {data: 'Other_Report_10_Category'},
  {data: 'Other_Report_10_Remark'},
  {data: 'Other_Report_11_Date'},
  {data: 'Other_Report_11_Link'},
  {data: 'Other_Report_11_Category'},
  {data: 'Other_Report_11_Remark'},
  {data: 'Other_Report_12_Date'},
  {data: 'Other_Report_12_Link'},
  {data: 'Other_Report_12_Category'},
  {data: 'Other_Report_12_Remark'},
  {data: 'Other_Report_13_Date'},
  {data: 'Other_Report_13_Link'},
  {data: 'Other_Report_13_Category'},
  {data: 'Other_Report_13_Remark'},
  {data: 'Other_Report_14_Date'},
  {data: 'Other_Report_14_Link'},
  {data: 'Other_Report_14_Category'},
  {data: 'Other_Report_14_Remark'},
  {data: 'Other_Report_15_Date'},
  {data: 'Other_Report_15_Link'},
  {data: 'Other_Report_15_Category'},
  {data: 'Other_Report_15_Remark'},
  {data: 'Other_Report_16_Date'},
  {data: 'Other_Report_16_Link'},
  {data: 'Other_Report_16_Category'},
  {data: 'Other_Report_16_Remark'},
  {data: 'Other_Report_17_Date'},
  {data: 'Other_Report_17_Link'},
  {data: 'Other_Report_17_Category'},
  {data: 'Other_Report_17_Remark'},
  {data: 'Other_Report_18_Date'},
  {data: 'Other_Report_18_Link'},
  {data: 'Other_Report_18_Category'},
  {data: 'Other_Report_18_Remark'},
  {data: 'Other_Report_19_Date'},
  {data: 'Other_Report_19_Link'},
  {data: 'Other_Report_19_Category'},
  {data: 'Other_Report_19_Remark'},
  {data: 'Other_Report_20_Date'},
  {data: 'Other_Report_20_Link'},
  {data: 'Other_Report_20_Category'},
  {data: 'Other_Report_20_Remark'},

  {data: 'EFA1_Programmer_Vt'},
  {data: 'EFA2_ATE_MOSAID_1'},
  {data: 'EFA3_ATE_MOSAID_2'},
  {data: 'EFA4_Electrical_Failure_Mode'},
  {data: 'EFA5_Layout_Tracing_Layer_1'},
  {data: 'EFA6_Layout_Tracing_Layer_2'},
  {data: 'PFA1_Root_Cause_Defect'},
  {data: 'CIP1_Process'},
  {data: 'CIP2_Test'},
  {data: 'CIP3_Test_Vector'},
  {data: 'CIP4_Process_CIP_Clean_D_C'},
  {data: 'CIP5_Test_CIP_Clean_D_C'},
  {data: 'EFA3_ATE_MOSAID_2_Details'},
  {data: 'SAP_Complete_Date'},
  {data: 'Close_Date_Monitor'},
  {data: 'TO'},
  {data: 'FM'},
  {data: 'Request_Date'},
  {data: 'P_O_No'},
  {
    data: 'Customers_Report_Included',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'Customers_Report_Link'},

  {data: 'TEL_Agency'},
  {data: 'FAX_Agency'},
  // {data: 'Mail_Agency'},
  {data: 'Customer_Defect_Rate'},
  {
    data: 'WECJ_FAE_Check',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {data: 'WECJ_FAE_Name'},
  {data: 'Module_Name'},
  {
    data: 'Action_Requested_Replacement',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Action_Requested_Credit',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Action_Requested_Pass_Fail',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Action_Requested_Further_Analysis_Report',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  {
    data: 'Action_Requested_Others',
    type: "checkbox",
    className:'htCenter',
    checkedTemplate: "1",
    uncheckedTemplate: "0"
  },
  // {data: 'Sales_Manager_WECJ'},
  { 
    data: 'Sales_Manager_WECJ',
    type: 'dropdown',
    source: null,
  },
  {data: 'Received_by_QA'},
  {data: 'QA_Receiving_Date_Time'},
  {data: 'WECJ_Remark'},
  // {data: 'create_time', readOnly: true },
  // {data: 'Detailed_Description_of_Symptom'},
  // {data: 'yobi'},
];


HandsonsData_OrderCFAR.data = [
];


// 2021.06.05 K.Ueoku 列追加「WECJ Remark」
HandsonsData_OrderCFAR.sampledata = [
  {
    delete_Flag: "0",
    partition_key: "",
    create_time: '',
    mode: "",
    type: "",
    Uniqu_No: "",
    status: "作成中",
    Disti_ID: "",
    Action_Requested_Credit: "0",
    Action_Requested_Further_Analysis_Report: "0",
    Action_Requested_Others: "0",
    Action_Requested_Pass_Fail: "0",
    Action_Requested_Replacement: "0",
    Application_Category: "",
    Application_Category_1: "",
    Application_Category_2: "",
    Application_Code: "",
    Application_Remark: "",
    Ass_y: "",
    CANCEL: "",
    CFAR: "",
    CIP1_Process: "",
    CIP2_Test: "",
    CIP3_Test_Vector: "",
    CIP4_Process_CIP_Clean_D_C: "",
    CIP5_Test_CIP_Clean_D_C: "",
    Close_Date_Monitor: "",
    Closed_Date: "",
    Closed_Month: "",
    Complaint_Symptom: "",
    Cross_Check_Done: "0",
    Customer: "",
    Customer_Defect_Rate: "",
    Customer_Group: "",
    Customer_ID: "",
    Customer_Request: "",
    Customers_Report_Included: "0",
    // Customers_Report_Included_File: "",
    Customers_Report_Included_File: [ "","","","","","","","","","" ],
    D_C: "",
    Days_from_Ship_out_to_Fail_Occurrence: "",
    Density: "",
    Distributer: "",
    Detailed_Description_of_Symptom: "",
    EFA1_Programmer_Vt: "",
    EFA2_ATE_MOSAID_1: "",
    EFA3_ATE_MOSAID_2: "",
    EFA3_ATE_MOSAID_2_Details: "",
    EFA4_Electrical_Failure_Mode: "",
    EFA5_Layout_Tracing_Layer_1: "",
    EFA6_Layout_Tracing_Layer_2: "",
    FA_Results: "",
    Failed_Location: "",
    Failed_Location_2: "",
    FAX_Agency: "",
    Final_Report_Issued: "",
    Final_Report_Issued_Link: "",
    Final_Report_Issued_to_Customer: "",
    FM: "",
    FT: "",
    Initial_Report_Issued: "",
    Initial_Report_Issued_Link: "",
    Initial_Report_Issued_to_Customer: "",
    Issued_Date: "",
    JQ_PIC: "",
    Lot_No: "",
    Memory_Type: "",
    Module_Name: "",
    Month: "",
    No: "",
    of_Bad_Parts: "",
    of_Parts_Used: "",
    Other_Report_10_Category: "",
    Other_Report_10_Date: "",
    Other_Report_10_Link: "",
    Other_Report_10_Remark: "",
    Other_Report_11_Category: "",
    Other_Report_11_Date: "",
    Other_Report_11_Link: "",
    Other_Report_11_Remark: "",
    Other_Report_12_Category: "",
    Other_Report_12_Date: "",
    Other_Report_12_Link: "",
    Other_Report_12_Remark: "",
    Other_Report_13_Category: "",
    Other_Report_13_Date: "",
    Other_Report_13_Link: "",
    Other_Report_13_Remark: "",
    Other_Report_14_Category: "",
    Other_Report_14_Date: "",
    Other_Report_14_Link: "",
    Other_Report_14_Remark: "",
    Other_Report_15_Category: "",
    Other_Report_15_Date: "",
    Other_Report_15_Link: "",
    Other_Report_15_Remark: "",
    Other_Report_16_Category: "",
    Other_Report_16_Date: "",
    Other_Report_16_Link: "",
    Other_Report_16_Remark: "",
    Other_Report_17_Category: "",
    Other_Report_17_Date: "",
    Other_Report_17_Link: "",
    Other_Report_17_Remark: "",
    Other_Report_18_Category: "",
    Other_Report_18_Date: "",
    Other_Report_18_Link: "",
    Other_Report_18_Remark: "",
    Other_Report_19_Category: "",
    Other_Report_19_Date: "",
    Other_Report_19_Link: "",
    Other_Report_19_Remark: "",
    Other_Report_1_Category: "",
    Other_Report_1_Date: "",
    Other_Report_1_Link: "",
    Other_Report_1_Remark: "",
    Other_Report_20_Category: "",
    Other_Report_20_Date: "",
    Other_Report_20_Link: "",
    Other_Report_20_Remark: "",
    Other_Report_2_Category: "",
    Other_Report_2_Date: "",
    Other_Report_2_Link: "",
    Other_Report_2_Remark: "",
    Other_Report_3_Category: "",
    Other_Report_3_Date: "",
    Other_Report_3_Link: "",
    Other_Report_3_Remark: "",
    Other_Report_4_Category: "",
    Other_Report_4_Date: "",
    Other_Report_4_Link: "",
    Other_Report_4_Remark: "",
    Other_Report_5_Category: "",
    Other_Report_5_Date: "",
    Other_Report_5_Link: "",
    Other_Report_5_Remark: "",
    Other_Report_6_Category: "",
    Other_Report_6_Date: "",
    Other_Report_6_Link: "",
    Other_Report_6_Remark: "",
    Other_Report_7_Category: "",
    Other_Report_7_Date: "",
    Other_Report_7_Link: "",
    Other_Report_7_Remark: "",
    Other_Report_8_Category: "",
    Other_Report_8_Date: "",
    Other_Report_8_Link: "",
    Other_Report_8_Remark: "",
    Other_Report_9_Category: "",
    Other_Report_9_Date: "",
    Other_Report_9_Link: "",
    Other_Report_9_Remark: "",
    P_O_No: "",
    Part_Number: "",
    PFA: "",
    PFA1_Root_Cause_Defect: "",
    Process: "",
    Product_Type: "",
    Project_No: "",
    QA_Receiving_Date_Time: "",
    QtyReturned_Packing_VI_Case_Count: "",
    Received_by_QA: "",
    Request_Date: "",
    Request_No: "",
    Request_Format: "",
    RESULT: "",
    Sales_Manager_WECJ: "",
    sample: "",
    Sample_arrival_Date_to_WECHQ: "",
    Sample_Return_Date: "",
    SAP_Complete_Date: "",
    Ship_Out_Date: "",
    TAT_Case_Close: "",
    TAT_Case_Close_Final_Report_Close: "",
    TAT_Final: "",
    TAT_Initial: "",
    TEL_Agency: "",
    Mail_Agency: "",
    TO: "",
    Total_Qty_Delivered: "",
    Unique_ID: "",
    Version: "",
    WECJ_FAE_Check: "0",
    WECJ_FAE_Name: "",
    WECJ_Remark: "",
    yobi: ""
  },
];






HandsonsData_OrderCFAR.colHeaders_Name_2 = [
  // 'partition_key', 

  // 'mode', 
  // 'type', 
  // 'Request_No', 
  // 'Project_No' ,

  // 'Unique_ID',
  // 'No',
  // 'Closed_Date',
  'Control No',
  'Status', 
  'Request Date',
  'Agency Person In Charge',
  'Notification No',
  'Customer<br>Request No',
  'Customer ID',
  'Customer',
  'Part Number',
  // '作成日', 
  // 'Closed_Month',
  // 'CANCEL',
  // 'Month',
  // 'Issued_Date',
  // 'sample',
  // 'Memory_Type',
  // 'Product_Type',
  // 'Process',
  // 'Version',
  // 'Density',
  // 'Application_Code',
  // 'Application_Category',
  // 'Application_Category_1',
  // 'Application_Category_2',
  // 'Application_Remark',
  // 'Customer_Group_group',
  // 'Distributer_Distributor',
  // 'Complaint_Symptom',
  // 'Failed_Location',
  // 'Failed_Location_2',
  // 'Ship_Out_Date',
  // 'Days_from_Ship-Days_from_Shipout_to_Fail_Occurrence_Fail_occurred_days_from_ship_out_date',
  // 'D_C',
  // 'Lot_No',
  // 'Total_Qty_Delivered',
  // 'of_Bad_Parts',
  // 'Qty_Returned_Packing_VI_Case_Count',
  // 'Of_Parts_Used_parts_used',
  // 'Initial_Report_Issued_Link',
  // 'Cross_Check_Done_Doone',
  // 'JQ_PIC',
  // 'Initial_Report_Issued',
  // 'Final_Report_Issued_Link',
  // 'Initial_Report_Issued_to_Customer_customer',
  // 'Final_Report_Issued',
  // 'Final_Report_Issued_to_Customer_Submitted_to_customer',
  // 'Sample_arrival_Date_to_WECHQ',
  // 'Ass_y',
  // 'FT',
  // 'TAT_Initial',
  // 'TAT_Final',
  // 'TAT_Case Close',
  // 'TAT_Case_Close_Final_Report_Close',
  // 'RESULT',
  // 'PFA',
  // 'FA_Results',
  // 'Sample_Return_Date_return_Date',
  // 'Other_Report_1_Date',
  // 'Other_Report_1_Link',
  // 'Other_Report_1_Category',
  // 'Other_Report_1_Remark',
  // 'Other_Report_2_Date',
  // 'Other_Report_2_Link',
  // 'Other_Report_2_Category',
  // 'Other_Report_2_Remark',
  // 'Other_Report_3_Date',
  // 'Other_Report_3_Link',
  // 'Other_Report_3_Category',
  // 'Other_Report_3_Remark',
  // 'Other_Report_4_Date',
  // 'Other_Report_4_Link',
  // 'Other_Report_4_Category',
  // 'Other_Report_4_Remark',
  // 'Other_Report_5_Date',
  // 'Other_Report_5_Link',
  // 'Other_Report_5_Category',
  // 'Other_Report_5_Remark',
  // 'Other_Report_6_Date',
  // 'Other_Report_6_Link',
  // 'Other_Report_6_Category',
  // 'Other_Report_6_Remark',
  // 'Other_Report_7_Date',
  // 'Other_Report_7_Link',
  // 'Other_Report_7_Category',
  // 'Other_Report_7_Remark',
  // 'Other_Report_8_Date',
  // 'Other_Report_8_Link',
  // 'Other_Report_8_Category',
  // 'Other_Report_8_Remark',
  // 'Other_Report_9_Date',
  // 'Other_Report_9_Link',
  // 'Other_Report_9_Category',
  // 'Other_Report_9_Remark',
  // 'Other_Report_10_Date',
  // 'Other_Report_10_Link',
  // 'Other_Report_10_Category',
  // 'Other_Report_10_Remark',
  // 'Other_Report_11_Date',
  // 'Other_Report_11_Link',
  // 'Other_Report_11_Category',
  // 'Other_Report_11_Remark',
  // 'Other_Report_12_Date',
  // 'Other_Report_12_Link',
  // 'Other_Report_12_Category',
  // 'Other_Report_12_Remark',
  // 'Other_Report_13_Date',
  // 'Other_Report_13_Link',
  // 'Other_Report_13_Category',
  // 'Other_Report_13_Remark',
  // 'Other_Report_14_Date',
  // 'Other_Report_14_Link',
  // 'Other_Report_14_Category',
  // 'Other_Report_14_Remark',
  // 'Other_Report_15_Date',
  // 'Other_Report_15_Link',
  // 'Other_Report_15_Category',
  // 'Other_Report_15_Remark',
  // 'Other_Report_16_Date',
  // 'Other_Report_16_Link',
  // 'Other_Report_16_Category',
  // 'Other_Report_16_Remark',
  // 'Other_Report_17_Date',
  // 'Other_Report_17_Link',
  // 'Other_Report_17_Category',
  // 'Other_Report_17_Remark',
  // 'Other_Report_18_Date',
  // 'Other_Report_18_Link',
  // 'Other_Report_18_Category',
  // 'Other_Report_18_Remark',
  // 'Other_Report_19_Date',
  // 'Other_Report_19_Link',
  // 'Other_Report_19_Category',
  // 'Other_Report_19_Remark',
  // 'Other_Report_20_Date',
  // 'Other_Report_20_Link',
  // 'Other_Report_20_Category',

  // 'Other_Report_20_Remark',
  // 'EFA1_Programmer_Vt',
  // 'EFA2_ATE_MOSAID_1',
  // 'EFA3_ATE_MOSAID_2',
  // 'EFA4_Electrical_Failure_Mode',
  // 'EFA5_Layout_Tracing_Layer_1',
  // 'EFA6_Layout_Tracing_Layer_2',
  // 'PFA1_Root_Cause_Defect',
  // 'CIP1_Process',
  // 'CIP2_Test',
  // 'CIP3_Test_Vector',
  // 'CIP4_Process_CIP_Clean_D_C',
  // 'CIP5_Test_CIP_Clean_D_C',
  // 'EFA3_ATE_MOSAID_2_Details',
  // 'SAP_Complete_Date',
  // 'Close_Date_Monitor_Close_Date',
  // 'TO',


  // 'P_O_No',
  // 'Customers_Report_Included',
  // 'TEL_Agency',
  // 'FAX_Agency',
  // 'Customer_Defect_Rate',
  // 'WECJ_FAE_Check',
  // 'WECJ_FAE_Name',
  // 'Module_Name',
  // 'Action_Requested_Replacement',
  // 'Action_Requested_Credit',
  // 'Action_Requested_Pass_Fail',
  // 'Action_Requested_Further_Analysis_Report',
  // 'Action_Requested_Others',
  // 'Sales_Manager_WECJ',
  // 'Received_by_QA',
  // 'QA_Receiving_Date_Time',


  // '123yobi' 
];



HandsonsData_OrderCFAR.columns_Data_2 = [
  // {data: 'partition_key'}, 
  // {data: 'create_time'}, 
  // {data: 'mode'}, 
  // {data: 'type'}, 
  // {data: 'Request_No'}, 
  // {data: 'Project_No'},

  // {data: 'Unique_ID'},
  // {data: 'No'},
  // {data: 'Closed_Date'},
  {data: 'Uniqu_No'},
  {data: 'status'}, 
  {data: 'Request_Date'},
  {data: 'FM'},
  {data: 'CFAR'},
  {data: 'Customer_Request'},
  {data: 'Customer_ID'},
  {data: 'Customer'},
  {data: 'Part_Number'},
  // {data: 'create_time', readOnly: true },
  // {data: 'Closed_Month'},
  // {data: 'CANCEL'},
  // {data: 'Month'},
  // {data: 'Issued_Date'},
  // {data: 'sample'},
  // {data: 'Memory_Type'},
  // {data: 'Product_Type'},
  // {data: 'Process'},
  // {data: 'Version'},
  // {data: 'Density'},
  // {data: 'Application_Code'},
  // {data: 'Application_Category'},
  // {data: 'Application_Category_1'},
  // {data: 'Application_Category_2'},
  // {data: 'Application_Remark'},
  // {data: 'Customer_Group'},
  // {data: 'Distributer'},
  // {data: 'Complaint_Symptom'},
  // {data: 'Failed_Location'},
  // {data: 'Failed_Location_2'},
  // {data: 'Ship_Out_Date'},
  // {data: 'Days_from_Ship-Days_from_Shipout_to_Fail_Occurrence_Fail_occurred_days_from_ship_out_date'},
  // {data: 'D_C'},
  // {data: 'Lot_No'},
  // {data: 'Total_Qty_Delivered'},
  // {data: 'of_Bad_Parts'},
  // {data: 'QtyReturned_Packing_VI_Case_Count'},
  // {data: 'of_Parts_Used'},
  // {data: 'Initial_Report_Issued_Link'},
  // {data: 'Cross_Check_Done_Doone'},
  // {data: 'JQ_PIC'},
  // {data: 'Initial_Report_Issued'},
  // {data: 'Final_Report_Issued_Link'},
  // {data: 'Initial_Report_Issued_to_Customer'},
  // {data: 'Final_Report_Issued'},
  // {data: 'Final_Report_Issued_to_Customer'},
  // {data: 'Sample_arrival_Date_to_WECHQ'},
  // {data: 'Ass_y'},
  // {data: 'FT'},
  // {data: 'TAT_Initial'},
  // {data: 'TAT_Final'},
  // {data: 'TAT_Case Close'},
  // {data: 'TAT_Case_Close_Final_Report_Close'},
  // {data: 'RESULT'},
  // {data: 'PFA'},
  // {data: 'FA_Results'},
  // {data: 'Sample_Return_Date'},
  // {data: 'Other_Report_1_Date'},
  // {data: 'Other_Report_1_Link'},
  // {data: 'Other_Report_1_Category'},
  // {data: 'Other_Report_1_Remark'},
  // {data: 'Other_Report_2_Date'},
  // {data: 'Other_Report_2_Link'},
  // {data: 'Other_Report_2_Category'},
  // {data: 'Other_Report_2_Remark'},
  // {data: 'Other_Report_3_Date'},
  // {data: 'Other_Report_3_Link'},
  // {data: 'Other_Report_3_Category'},
  // {data: 'Other_Report_3_Remark'},
  // {data: 'Other_Report_4_Date'},
  // {data: 'Other_Report_4_Link'},
  // {data: 'Other_Report_4_Category'},
  // {data: 'Other_Report_4_Remark'},
  // {data: 'Other_Report_5_Date'},
  // {data: 'Other_Report_5_Link'},
  // {data: 'Other_Report_5_Category'},
  // {data: 'Other_Report_5_Remark'},
  // {data: 'Other_Report_6_Date'},
  // {data: 'Other_Report_6_Link'},
  // {data: 'Other_Report_6_Category'},
  // {data: 'Other_Report_6_Remark'},
  // {data: 'Other_Report_7_Date'},
  // {data: 'Other_Report_7_Link'},
  // {data: 'Other_Report_7_Category'},
  // {data: 'Other_Report_7_Remark'},
  // {data: 'Other_Report_8_Date'},
  // {data: 'Other_Report_8_Link'},
  // {data: 'Other_Report_8_Category'},
  // {data: 'Other_Report_8_Remark'},
  // {data: 'Other_Report_9_Date'},
  // {data: 'Other_Report_9_Link'},
  // {data: 'Other_Report_9_Category'},
  // {data: 'Other_Report_9_Remark'},
  // {data: 'Other_Report_10_Date'},
  // {data: 'Other_Report_10_Link'},
  // {data: 'Other_Report_10_Category'},
  // {data: 'Other_Report_10_Remark'},
  // {data: 'Other_Report_11_Date'},
  // {data: 'Other_Report_11_Link'},
  // {data: 'Other_Report_11_Category'},
  // {data: 'Other_Report_11_Remark'},
  // {data: 'Other_Report_12_Date'},
  // {data: 'Other_Report_12_Link'},
  // {data: 'Other_Report_12_Category'},
  // {data: 'Other_Report_12_Remark'},
  // {data: 'Other_Report_13_Date'},
  // {data: 'Other_Report_13_Link'},
  // {data: 'Other_Report_13_Category'},
  // {data: 'Other_Report_13_Remark'},
  // {data: 'Other_Report_14_Date'},
  // {data: 'Other_Report_14_Link'},
  // {data: 'Other_Report_14_Category'},
  // {data: 'Other_Report_14_Remark'},
  // {data: 'Other_Report_15_Date'},
  // {data: 'Other_Report_15_Link'},
  // {data: 'Other_Report_15_Category'},
  // {data: 'Other_Report_15_Remark'},
  // {data: 'Other_Report_16_Date'},
  // {data: 'Other_Report_16_Link'},
  // {data: 'Other_Report_16_Category'},
  // {data: 'Other_Report_16_Remark'},
  // {data: 'Other_Report_17_Date'},
  // {data: 'Other_Report_17_Link'},
  // {data: 'Other_Report_17_Category'},
  // {data: 'Other_Report_17_Remark'},
  // {data: 'Other_Report_18_Date'},
  // {data: 'Other_Report_18_Link'},
  // {data: 'Other_Report_18_Category'},
  // {data: 'Other_Report_18_Remark'},
  // {data: 'Other_Report_19_Date'},
  // {data: 'Other_Report_19_Link'},
  // {data: 'Other_Report_19_Category'},
  // {data: 'Other_Report_19_Remark'},
  // {data: 'Other_Report_20_Date'},
  // {data: 'Other_Report_20_Link'},
  // {data: 'Other_Report_20_Category'},
  // {data: 'Other_Report_20_Remark'},
  // {data: 'EFA1_Programmer_Vt'},
  // {data: 'EFA2_ATE_MOSAID_1'},
  // {data: 'EFA3_ATE_MOSAID_2'},
  // {data: 'EFA4_Electrical_Failure_Mode'},
  // {data: 'EFA5_Layout_Tracing_Layer_1'},
  // {data: 'EFA6_Layout_Tracing_Layer_2'},
  // {data: 'PFA1_Root_Cause_Defect'},
  // {data: 'CIP1_Process'},
  // {data: 'CIP2_Test'},
  // {data: 'CIP3_Test_Vector'},
  // {data: 'CIP4_Process_CIP_Clean_D_C'},
  // {data: 'CIP5_Test_CIP_Clean_D_C'},
  // {data: 'EFA3_ATE_MOSAID_2_Details'},
  // {data: 'SAP_Complete_Date'},
  // {data: 'Close_Date_Monitor_Close_Date'},
  // {data: 'TO'},

  // {data: 'P_O_No'},
  // {data: 'Customers_Report_Included'},
  // {data: 'TEL_Agency'},
  // {data: 'FAX_Agency'},
  // {data: 'Customer_Defect_Rate'},
  // {data: 'WECJ_FAE_Check'},
  // {data: 'WECJ_FAE_Name'},
  // {data: 'Module_Name'},
  // {data: 'Action_Requested_Replacement'},
  // {data: 'Action_Requested_Credit'},
  // {data: 'Action_Requested_Pass_Fail'},
  // {data: 'Action_Requested_Further_Analysis_Report'},
  // {data: 'Action_Requested_Others'},
  // {data: 'Sales_Manager_WECJ'},
  // {data: 'Received_by_QA'},
  // {data: 'QA_Receiving_Date_Time'},

  // {data: 'yobi'},
];










HandsonsData_OrderCFAR.testdata = [
  {
  Action_Requested_Credit: "Action_Requested_Credit",
  Action_Requested_Further_Analysis_Report: "Action_Requested_Further_Analysis_Report",
  Action_Requested_Others: "Action_Requested_Others",
  Action_Requested_Pass_Fail: "Action_Requested_Pass_Fail",
  Action_Requested_Replacement: "Action_Requested_Replacement",
  Application_Category: "Application_Category",
  Application_Category_1: "Application_Category_1",
  Application_Category_2: "Application_Category_2",
  Application_Code: "Application_Code",
  Application_Remark: "Application_Remark",
  Ass_y: "Ass_y",
  CANCEL: "CANCEL",
  CFAR: "CFAR",
  CIP1_Process: "CIP1_Process",
  CIP2_Test: "CIP2_Test",
  CIP3_Test_Vector: "CIP3_Test_Vector",
  CIP4_Process_CIP_Clean_D_C: "CIP4_Process_CIP_Clean_D_C",
  CIP5_Test_CIP_Clean_D_C: "CIP5_Test_CIP_Clean_D_C",
  Close_Date_Monitor: "Close_Date_Monitor",
  Closed_Date: "Closed_Date",
  Closed_Month: "Closed_Month",
  Complaint_Symptom: "Complaint_Symptom",
  create_time: "20200619163601002",
  Cross_Check_Done: "Cross_Check_Done",
  Customer: "Customer",
  Customer_Defect_Rate: "Customer_Defect_Rate",
  Customer_Group: "Customer_Group",
  Customer_ID: "Customer_ID",
  Customer_Request: "Customer_Request",
  Customers_Report_Included: "Customers_Report_Included",
  D_C: "D_C",
  Days_from_Ship_out_to_Fail_Occurrence: "Days_from_Ship_out_to_Fail_Occurrence",
  Density: "Density",
  Distributer: "Distributer",
  EFA1_Programmer_Vt: "EFA1_Programmer_Vt",
  EFA2_ATE_MOSAID_1: "EFA2_ATE_MOSAID_1",
  EFA3_ATE_MOSAID_2: "EFA3_ATE_MOSAID_2",
  EFA3_ATE_MOSAID_2_Details: "EFA3_ATE_MOSAID_2_Details",
  EFA4_Electrical_Failure_Mode: "EFA4_Electrical_Failure_Mode",
  EFA5_Layout_Tracing_Layer_1: "EFA5_Layout_Tracing_Layer_1",
  EFA6_Layout_Tracing_Layer_2: "EFA6_Layout_Tracing_Layer_2",
  FA_Results: "FA_Results",
  Failed_Location: "Failed_Location",
  Failed_Location_2: "Failed_Location_2",
  FAX_Agency: "FAX_Agency",
  Final_Report_Issued: "Final_Report_Issued",
  Final_Report_Issued_Link: "Final_Report_Issued_Link",
  Final_Report_Issued_to_Customer: "Final_Report_Issued_to_Customer",
  FM: "FM",
  FT: "FT",
  Initial_Report_Issued: "Initial_Report_Issued",
  Initial_Report_Issued_Link: "Initial_Report_Issued_Link",
  Initial_Report_Issued_to_Customer: "Initial_Report_Issued_to_Customer",
  Issued_Date: "Issued_Date",
  JQ_PIC: "JQ_PIC",
  Lot_No: "Lot_No",
  Memory_Type: "Memory_Type",
  mode: "mode",
  Module_Name: "Module_Name",
  Month: "Month",
  No: "No",
  of_Bad_Parts: "of_Bad_Parts",
  of_Parts_Used: "of_Parts_Used",
  Other_Report_10_Category: "Other_Report_10_Category",
  Other_Report_10_Date: "Other_Report_10_Date",
  Other_Report_10_Link: "Other_Report_10_Link",
  Other_Report_10_Remark: "Other_Report_10_Remark",
  Other_Report_11_Category: "Other_Report_11_Category",
  Other_Report_11_Date: "Other_Report_11_Date",
  Other_Report_11_Link: "Other_Report_11_Link",
  Other_Report_11_Remark: "Other_Report_11_Remark",
  Other_Report_12_Category: "Other_Report_12_Category",
  Other_Report_12_Date: "Other_Report_12_Date",
  Other_Report_12_Link: "Other_Report_12_Link",
  Other_Report_12_Remark: "Other_Report_12_Remark",
  Other_Report_13_Category: "Other_Report_13_Category",
  Other_Report_13_Date: "Other_Report_13_Date",
  Other_Report_13_Link: "Other_Report_13_Link",
  Other_Report_13_Remark: "Other_Report_13_Remark",
  Other_Report_14_Category: "Other_Report_14_Category",
  Other_Report_14_Date: "Other_Report_14_Date",
  Other_Report_14_Link: "Other_Report_14_Link",
  Other_Report_14_Remark: "Other_Report_14_Remark",
  Other_Report_15_Category: "Other_Report_15_Category",
  Other_Report_15_Date: "Other_Report_15_Date",
  Other_Report_15_Link: "Other_Report_15_Link",
  Other_Report_15_Remark: "Other_Report_15_Remark",
  Other_Report_16_Category: "Other_Report_16_Category",
  Other_Report_16_Date: "Other_Report_16_Date",
  Other_Report_16_Link: "Other_Report_16_Link",
  Other_Report_16_Remark: "Other_Report_16_Remark",
  Other_Report_17_Category: "Other_Report_17_Category",
  Other_Report_17_Date: "Other_Report_17_Date",
  Other_Report_17_Link: "Other_Report_17_Link",
  Other_Report_17_Remark: "Other_Report_17_Remark",
  Other_Report_18_Category: "Other_Report_18_Category",
  Other_Report_18_Date: "Other_Report_18_Date",
  Other_Report_18_Link: "Other_Report_18_Link",
  Other_Report_18_Remark: "Other_Report_18_Remark",
  Other_Report_19_Category: "Other_Report_19_Category",
  Other_Report_19_Date: "Other_Report_19_Date",
  Other_Report_19_Link: "Other_Report_19_Link",
  Other_Report_19_Remark: "Other_Report_19_Remark",
  Other_Report_1_Category: "Other_Report_1_Category",
  Other_Report_1_Date: "Other_Report_1_Date",
  Other_Report_1_Link: "Other_Report_1_Link",
  Other_Report_1_Remark: "Other_Report_1_Remark",
  Other_Report_20_Category: "Other_Report_20_Category",
  Other_Report_20_Date: "Other_Report_20_Date",
  Other_Report_20_Link: "Other_Report_20_Link",
  Other_Report_20_Remark: "Other_Report_20_Remark",
  Other_Report_2_Category: "Other_Report_2_Category",
  Other_Report_2_Date: "Other_Report_2_Date",
  Other_Report_2_Link: "Other_Report_2_Link",
  Other_Report_2_Remark: "Other_Report_2_Remark",
  Other_Report_3_Category: "Other_Report_3_Category",
  Other_Report_3_Date: "Other_Report_3_Date",
  Other_Report_3_Link: "Other_Report_3_Link",
  Other_Report_3_Remark: "Other_Report_3_Remark",
  Other_Report_4_Category: "Other_Report_4_Category",
  Other_Report_4_Date: "Other_Report_4_Date",
  Other_Report_4_Link: "Other_Report_4_Link",
  Other_Report_4_Remark: "Other_Report_4_Remark",
  Other_Report_5_Category: "Other_Report_5_Category",
  Other_Report_5_Date: "Other_Report_5_Date",
  Other_Report_5_Link: "Other_Report_5_Link",
  Other_Report_5_Remark: "Other_Report_5_Remark",
  Other_Report_6_Category: "Other_Report_6_Category",
  Other_Report_6_Date: "Other_Report_6_Date",
  Other_Report_6_Link: "Other_Report_6_Link",
  Other_Report_6_Remark: "Other_Report_6_Remark",
  Other_Report_7_Category: "Other_Report_7_Category",
  Other_Report_7_Date: "Other_Report_7_Date",
  Other_Report_7_Link: "Other_Report_7_Link",
  Other_Report_7_Remark: "Other_Report_7_Remark",
  Other_Report_8_Category: "Other_Report_8_Category",
  Other_Report_8_Date: "Other_Report_8_Date",
  Other_Report_8_Link: "Other_Report_8_Link",
  Other_Report_8_Remark: "Other_Report_8_Remark",
  Other_Report_9_Category: "Other_Report_9_Category",
  Other_Report_9_Date: "Other_Report_9_Date",
  Other_Report_9_Link: "Other_Report_9_Link",
  Other_Report_9_Remark: "Other_Report_9_Remark",
  P_O_No: "P_O_No",
  Part_Number: "Part_Number",
  partition_key: "CFAR",
  PFA: "PFA",
  PFA1_Root_Cause_Defect: "PFA1_Root_Cause_Defect",
  Process: "Process",
  Product_Type: "Product_Type",
  Project_No: "Project_No",
  QA_Receiving_Date_Time: "QA_Receiving_Date_Time",
  QtyReturned_Packing_VI_Case_Count: "QtyReturned_Packing_VI_Case_Count",
  Received_by_QA: "Received_by_QA",
  Request_Date: "Request_Date",
  Request_No: "Request_No",
  RESULT: "RESULT",
  Sales_Manager_WECJ: "Sales_Manager_WECJ",
  sample: "sample",
  Sample_arrival_Date_to_WECHQ: "Sample_arrival_Date_to_WECHQ",
  Sample_Return_Date: "Sample_Return_Date",
  SAP_Complete_Date: "SAP_Complete_Date",
  Ship_Out_Date: "Ship_Out_Date",
  status: "status",
  TAT_Case_Close: "TAT_Case_Close",
  TAT_Case_Close_Final_Report_Close: "TAT_Case_Close_Final_Report_Close",
  TAT_Final: "TAT_Final",
  TAT_Initial: "TAT_Initial",
  TEL_Agency: "TEL_Agency",
  TO: "TO",
  Total_Qty_Delivered: "Total_Qty_Delivered",
  type: "type",
  Unique_ID: "Unique_ID",
  Version: "Version",
  WECJ_FAE_Check: "WECJ_FAE_Check",
  WECJ_FAE_Name: "WECJ_FAE_Name",
  yobi: "yobi"
  },
  {
    Action_Requested_Credit: "Action_Requested_Credit",
    Action_Requested_Further_Analysis_Report: "Action_Requested_Further_Analysis_Report",
    Action_Requested_Others: "Action_Requested_Others",
    Action_Requested_Pass_Fail: "Action_Requested_Pass_Fail",
    Action_Requested_Replacement: "Action_Requested_Replacement",
    Application_Category: "Application_Category",
    Application_Category_1: "Application_Category_1",
    Application_Category_2: "Application_Category_2",
    Application_Code: "Application_Code",
    Application_Remark: "Application_Remark",
    Ass_y: "Ass_y",
    CANCEL: "CANCEL",
    CFAR: "CFAR",
    CIP1_Process: "CIP1_Process",
    CIP2_Test: "CIP2_Test",
    CIP3_Test_Vector: "CIP3_Test_Vector",
    CIP4_Process_CIP_Clean_D_C: "CIP4_Process_CIP_Clean_D_C",
    CIP5_Test_CIP_Clean_D_C: "CIP5_Test_CIP_Clean_D_C",
    Close_Date_Monitor: "Close_Date_Monitor",
    Closed_Date: "Closed_Date",
    Closed_Month: "Closed_Month",
    Complaint_Symptom: "Complaint_Symptom",
    create_time: "20200619163601002",
    Cross_Check_Done: "Cross_Check_Done",
    Customer: "Customer",
    Customer_Defect_Rate: "Customer_Defect_Rate",
    Customer_Group: "Customer_Group",
    Customer_ID: "Customer_ID",
    Customer_Request: "Customer_Request",
    Customers_Report_Included: "Customers_Report_Included",
    D_C: "D_C",
    Days_from_Ship_out_to_Fail_Occurrence: "Days_from_Ship_out_to_Fail_Occurrence",
    Density: "Density",
    Distributer: "Distributer",
    EFA1_Programmer_Vt: "EFA1_Programmer_Vt",
    EFA2_ATE_MOSAID_1: "EFA2_ATE_MOSAID_1",
    EFA3_ATE_MOSAID_2: "EFA3_ATE_MOSAID_2",
    EFA3_ATE_MOSAID_2_Details: "EFA3_ATE_MOSAID_2_Details",
    EFA4_Electrical_Failure_Mode: "EFA4_Electrical_Failure_Mode",
    EFA5_Layout_Tracing_Layer_1: "EFA5_Layout_Tracing_Layer_1",
    EFA6_Layout_Tracing_Layer_2: "EFA6_Layout_Tracing_Layer_2",
    FA_Results: "FA_Results",
    Failed_Location: "Failed_Location",
    Failed_Location_2: "Failed_Location_2",
    FAX_Agency: "FAX_Agency",
    Final_Report_Issued: "Final_Report_Issued",
    Final_Report_Issued_Link: "Final_Report_Issued_Link",
    Final_Report_Issued_to_Customer: "Final_Report_Issued_to_Customer",
    FM: "FM",
    FT: "FT",
    Initial_Report_Issued: "Initial_Report_Issued",
    Initial_Report_Issued_Link: "Initial_Report_Issued_Link",
    Initial_Report_Issued_to_Customer: "Initial_Report_Issued_to_Customer",
    Issued_Date: "Issued_Date",
    JQ_PIC: "JQ_PIC",
    Lot_No: "Lot_No",
    Memory_Type: "Memory_Type",
    mode: "mode",
    Module_Name: "Module_Name",
    Month: "Month",
    No: "No",
    of_Bad_Parts: "of_Bad_Parts",
    of_Parts_Used: "of_Parts_Used",
    Other_Report_10_Category: "Other_Report_10_Category",
    Other_Report_10_Date: "Other_Report_10_Date",
    Other_Report_10_Link: "Other_Report_10_Link",
    Other_Report_10_Remark: "Other_Report_10_Remark",
    Other_Report_11_Category: "Other_Report_11_Category",
    Other_Report_11_Date: "Other_Report_11_Date",
    Other_Report_11_Link: "Other_Report_11_Link",
    Other_Report_11_Remark: "Other_Report_11_Remark",
    Other_Report_12_Category: "Other_Report_12_Category",
    Other_Report_12_Date: "Other_Report_12_Date",
    Other_Report_12_Link: "Other_Report_12_Link",
    Other_Report_12_Remark: "Other_Report_12_Remark",
    Other_Report_13_Category: "Other_Report_13_Category",
    Other_Report_13_Date: "Other_Report_13_Date",
    Other_Report_13_Link: "Other_Report_13_Link",
    Other_Report_13_Remark: "Other_Report_13_Remark",
    Other_Report_14_Category: "Other_Report_14_Category",
    Other_Report_14_Date: "Other_Report_14_Date",
    Other_Report_14_Link: "Other_Report_14_Link",
    Other_Report_14_Remark: "Other_Report_14_Remark",
    Other_Report_15_Category: "Other_Report_15_Category",
    Other_Report_15_Date: "Other_Report_15_Date",
    Other_Report_15_Link: "Other_Report_15_Link",
    Other_Report_15_Remark: "Other_Report_15_Remark",
    Other_Report_16_Category: "Other_Report_16_Category",
    Other_Report_16_Date: "Other_Report_16_Date",
    Other_Report_16_Link: "Other_Report_16_Link",
    Other_Report_16_Remark: "Other_Report_16_Remark",
    Other_Report_17_Category: "Other_Report_17_Category",
    Other_Report_17_Date: "Other_Report_17_Date",
    Other_Report_17_Link: "Other_Report_17_Link",
    Other_Report_17_Remark: "Other_Report_17_Remark",
    Other_Report_18_Category: "Other_Report_18_Category",
    Other_Report_18_Date: "Other_Report_18_Date",
    Other_Report_18_Link: "Other_Report_18_Link",
    Other_Report_18_Remark: "Other_Report_18_Remark",
    Other_Report_19_Category: "Other_Report_19_Category",
    Other_Report_19_Date: "Other_Report_19_Date",
    Other_Report_19_Link: "Other_Report_19_Link",
    Other_Report_19_Remark: "Other_Report_19_Remark",
    Other_Report_1_Category: "Other_Report_1_Category",
    Other_Report_1_Date: "Other_Report_1_Date",
    Other_Report_1_Link: "Other_Report_1_Link",
    Other_Report_1_Remark: "Other_Report_1_Remark",
    Other_Report_20_Category: "Other_Report_20_Category",
    Other_Report_20_Date: "Other_Report_20_Date",
    Other_Report_20_Link: "Other_Report_20_Link",
    Other_Report_20_Remark: "Other_Report_20_Remark",
    Other_Report_2_Category: "Other_Report_2_Category",
    Other_Report_2_Date: "Other_Report_2_Date",
    Other_Report_2_Link: "Other_Report_2_Link",
    Other_Report_2_Remark: "Other_Report_2_Remark",
    Other_Report_3_Category: "Other_Report_3_Category",
    Other_Report_3_Date: "Other_Report_3_Date",
    Other_Report_3_Link: "Other_Report_3_Link",
    Other_Report_3_Remark: "Other_Report_3_Remark",
    Other_Report_4_Category: "Other_Report_4_Category",
    Other_Report_4_Date: "Other_Report_4_Date",
    Other_Report_4_Link: "Other_Report_4_Link",
    Other_Report_4_Remark: "Other_Report_4_Remark",
    Other_Report_5_Category: "Other_Report_5_Category",
    Other_Report_5_Date: "Other_Report_5_Date",
    Other_Report_5_Link: "Other_Report_5_Link",
    Other_Report_5_Remark: "Other_Report_5_Remark",
    Other_Report_6_Category: "Other_Report_6_Category",
    Other_Report_6_Date: "Other_Report_6_Date",
    Other_Report_6_Link: "Other_Report_6_Link",
    Other_Report_6_Remark: "Other_Report_6_Remark",
    Other_Report_7_Category: "Other_Report_7_Category",
    Other_Report_7_Date: "Other_Report_7_Date",
    Other_Report_7_Link: "Other_Report_7_Link",
    Other_Report_7_Remark: "Other_Report_7_Remark",
    Other_Report_8_Category: "Other_Report_8_Category",
    Other_Report_8_Date: "Other_Report_8_Date",
    Other_Report_8_Link: "Other_Report_8_Link",
    Other_Report_8_Remark: "Other_Report_8_Remark",
    Other_Report_9_Category: "Other_Report_9_Category",
    Other_Report_9_Date: "Other_Report_9_Date",
    Other_Report_9_Link: "Other_Report_9_Link",
    Other_Report_9_Remark: "Other_Report_9_Remark",
    P_O_No: "P_O_No",
    Part_Number: "Part_Number",
    partition_key: "CFAR",
    PFA: "PFA",
    PFA1_Root_Cause_Defect: "PFA1_Root_Cause_Defect",
    Process: "Process",
    Product_Type: "Product_Type",
    Project_No: "Project_No",
    QA_Receiving_Date_Time: "QA_Receiving_Date_Time",
    QtyReturned_Packing_VI_Case_Count: "QtyReturned_Packing_VI_Case_Count",
    Received_by_QA: "Received_by_QA",
    Request_Date: "Request_Date",
    Request_No: "Request_No",
    RESULT: "RESULT",
    Sales_Manager_WECJ: "Sales_Manager_WECJ",
    sample: "sample",
    Sample_arrival_Date_to_WECHQ: "Sample_arrival_Date_to_WECHQ",
    Sample_Return_Date: "Sample_Return_Date",
    SAP_Complete_Date: "SAP_Complete_Date",
    Ship_Out_Date: "Ship_Out_Date",
    status: "status",
    TAT_Case_Close: "TAT_Case_Close",
    TAT_Case_Close_Final_Report_Close: "TAT_Case_Close_Final_Report_Close",
    TAT_Final: "TAT_Final",
    TAT_Initial: "TAT_Initial",
    TEL_Agency: "TEL_Agency",
    TO: "TO",
    Total_Qty_Delivered: "Total_Qty_Delivered",
    type: "type",
    Unique_ID: "Unique_ID",
    Version: "Version",
    WECJ_FAE_Check: "WECJ_FAE_Check",
    WECJ_FAE_Name: "WECJ_FAE_Name",
    yobi: "yobi"
    },
    {
      Action_Requested_Credit: "Action_Requested_Credit",
      Action_Requested_Further_Analysis_Report: "Action_Requested_Further_Analysis_Report",
      Action_Requested_Others: "Action_Requested_Others",
      Action_Requested_Pass_Fail: "Action_Requested_Pass_Fail",
      Action_Requested_Replacement: "Action_Requested_Replacement",
      Application_Category: "Application_Category",
      Application_Category_1: "Application_Category_1",
      Application_Category_2: "Application_Category_2",
      Application_Code: "Application_Code",
      Application_Remark: "Application_Remark",
      Ass_y: "Ass_y",
      CANCEL: "CANCEL",
      CFAR: "CFAR",
      CIP1_Process: "CIP1_Process",
      CIP2_Test: "CIP2_Test",
      CIP3_Test_Vector: "CIP3_Test_Vector",
      CIP4_Process_CIP_Clean_D_C: "CIP4_Process_CIP_Clean_D_C",
      CIP5_Test_CIP_Clean_D_C: "CIP5_Test_CIP_Clean_D_C",
      Close_Date_Monitor: "Close_Date_Monitor",
      Closed_Date: "Closed_Date",
      Closed_Month: "Closed_Month",
      Complaint_Symptom: "Complaint_Symptom",
      create_time: "20200619163601002",
      Cross_Check_Done: "Cross_Check_Done",
      Customer: "Customer",
      Customer_Defect_Rate: "Customer_Defect_Rate",
      Customer_Group: "Customer_Group",
      Customer_ID: "Customer_ID",
      Customer_Request: "Customer_Request",
      Customers_Report_Included: "Customers_Report_Included",
      D_C: "D_C",
      Days_from_Ship_out_to_Fail_Occurrence: "Days_from_Ship_out_to_Fail_Occurrence",
      Density: "Density",
      Distributer: "Distributer",
      EFA1_Programmer_Vt: "EFA1_Programmer_Vt",
      EFA2_ATE_MOSAID_1: "EFA2_ATE_MOSAID_1",
      EFA3_ATE_MOSAID_2: "EFA3_ATE_MOSAID_2",
      EFA3_ATE_MOSAID_2_Details: "EFA3_ATE_MOSAID_2_Details",
      EFA4_Electrical_Failure_Mode: "EFA4_Electrical_Failure_Mode",
      EFA5_Layout_Tracing_Layer_1: "EFA5_Layout_Tracing_Layer_1",
      EFA6_Layout_Tracing_Layer_2: "EFA6_Layout_Tracing_Layer_2",
      FA_Results: "FA_Results",
      Failed_Location: "Failed_Location",
      Failed_Location_2: "Failed_Location_2",
      FAX_Agency: "FAX_Agency",
      Final_Report_Issued: "Final_Report_Issued",
      Final_Report_Issued_Link: "Final_Report_Issued_Link",
      Final_Report_Issued_to_Customer: "Final_Report_Issued_to_Customer",
      FM: "FM",
      FT: "FT",
      Initial_Report_Issued: "Initial_Report_Issued",
      Initial_Report_Issued_Link: "Initial_Report_Issued_Link",
      Initial_Report_Issued_to_Customer: "Initial_Report_Issued_to_Customer",
      Issued_Date: "Issued_Date",
      JQ_PIC: "JQ_PIC",
      Lot_No: "Lot_No",
      Memory_Type: "Memory_Type",
      mode: "mode",
      Module_Name: "Module_Name",
      Month: "Month",
      No: "No",
      of_Bad_Parts: "of_Bad_Parts",
      of_Parts_Used: "of_Parts_Used",
      Other_Report_10_Category: "Other_Report_10_Category",
      Other_Report_10_Date: "Other_Report_10_Date",
      Other_Report_10_Link: "Other_Report_10_Link",
      Other_Report_10_Remark: "Other_Report_10_Remark",
      Other_Report_11_Category: "Other_Report_11_Category",
      Other_Report_11_Date: "Other_Report_11_Date",
      Other_Report_11_Link: "Other_Report_11_Link",
      Other_Report_11_Remark: "Other_Report_11_Remark",
      Other_Report_12_Category: "Other_Report_12_Category",
      Other_Report_12_Date: "Other_Report_12_Date",
      Other_Report_12_Link: "Other_Report_12_Link",
      Other_Report_12_Remark: "Other_Report_12_Remark",
      Other_Report_13_Category: "Other_Report_13_Category",
      Other_Report_13_Date: "Other_Report_13_Date",
      Other_Report_13_Link: "Other_Report_13_Link",
      Other_Report_13_Remark: "Other_Report_13_Remark",
      Other_Report_14_Category: "Other_Report_14_Category",
      Other_Report_14_Date: "Other_Report_14_Date",
      Other_Report_14_Link: "Other_Report_14_Link",
      Other_Report_14_Remark: "Other_Report_14_Remark",
      Other_Report_15_Category: "Other_Report_15_Category",
      Other_Report_15_Date: "Other_Report_15_Date",
      Other_Report_15_Link: "Other_Report_15_Link",
      Other_Report_15_Remark: "Other_Report_15_Remark",
      Other_Report_16_Category: "Other_Report_16_Category",
      Other_Report_16_Date: "Other_Report_16_Date",
      Other_Report_16_Link: "Other_Report_16_Link",
      Other_Report_16_Remark: "Other_Report_16_Remark",
      Other_Report_17_Category: "Other_Report_17_Category",
      Other_Report_17_Date: "Other_Report_17_Date",
      Other_Report_17_Link: "Other_Report_17_Link",
      Other_Report_17_Remark: "Other_Report_17_Remark",
      Other_Report_18_Category: "Other_Report_18_Category",
      Other_Report_18_Date: "Other_Report_18_Date",
      Other_Report_18_Link: "Other_Report_18_Link",
      Other_Report_18_Remark: "Other_Report_18_Remark",
      Other_Report_19_Category: "Other_Report_19_Category",
      Other_Report_19_Date: "Other_Report_19_Date",
      Other_Report_19_Link: "Other_Report_19_Link",
      Other_Report_19_Remark: "Other_Report_19_Remark",
      Other_Report_1_Category: "Other_Report_1_Category",
      Other_Report_1_Date: "Other_Report_1_Date",
      Other_Report_1_Link: "Other_Report_1_Link",
      Other_Report_1_Remark: "Other_Report_1_Remark",
      Other_Report_20_Category: "Other_Report_20_Category",
      Other_Report_20_Date: "Other_Report_20_Date",
      Other_Report_20_Link: "Other_Report_20_Link",
      Other_Report_20_Remark: "Other_Report_20_Remark",
      Other_Report_2_Category: "Other_Report_2_Category",
      Other_Report_2_Date: "Other_Report_2_Date",
      Other_Report_2_Link: "Other_Report_2_Link",
      Other_Report_2_Remark: "Other_Report_2_Remark",
      Other_Report_3_Category: "Other_Report_3_Category",
      Other_Report_3_Date: "Other_Report_3_Date",
      Other_Report_3_Link: "Other_Report_3_Link",
      Other_Report_3_Remark: "Other_Report_3_Remark",
      Other_Report_4_Category: "Other_Report_4_Category",
      Other_Report_4_Date: "Other_Report_4_Date",
      Other_Report_4_Link: "Other_Report_4_Link",
      Other_Report_4_Remark: "Other_Report_4_Remark",
      Other_Report_5_Category: "Other_Report_5_Category",
      Other_Report_5_Date: "Other_Report_5_Date",
      Other_Report_5_Link: "Other_Report_5_Link",
      Other_Report_5_Remark: "Other_Report_5_Remark",
      Other_Report_6_Category: "Other_Report_6_Category",
      Other_Report_6_Date: "Other_Report_6_Date",
      Other_Report_6_Link: "Other_Report_6_Link",
      Other_Report_6_Remark: "Other_Report_6_Remark",
      Other_Report_7_Category: "Other_Report_7_Category",
      Other_Report_7_Date: "Other_Report_7_Date",
      Other_Report_7_Link: "Other_Report_7_Link",
      Other_Report_7_Remark: "Other_Report_7_Remark",
      Other_Report_8_Category: "Other_Report_8_Category",
      Other_Report_8_Date: "Other_Report_8_Date",
      Other_Report_8_Link: "Other_Report_8_Link",
      Other_Report_8_Remark: "Other_Report_8_Remark",
      Other_Report_9_Category: "Other_Report_9_Category",
      Other_Report_9_Date: "Other_Report_9_Date",
      Other_Report_9_Link: "Other_Report_9_Link",
      Other_Report_9_Remark: "Other_Report_9_Remark",
      P_O_No: "P_O_No",
      Part_Number: "Part_Number",
      partition_key: "CFAR",
      PFA: "PFA",
      PFA1_Root_Cause_Defect: "PFA1_Root_Cause_Defect",
      Process: "Process",
      Product_Type: "Product_Type",
      Project_No: "Project_No",
      QA_Receiving_Date_Time: "QA_Receiving_Date_Time",
      QtyReturned_Packing_VI_Case_Count: "QtyReturned_Packing_VI_Case_Count",
      Received_by_QA: "Received_by_QA",
      Request_Date: "Request_Date",
      Request_No: "Request_No",
      RESULT: "RESULT",
      Sales_Manager_WECJ: "Sales_Manager_WECJ",
      sample: "sample",
      Sample_arrival_Date_to_WECHQ: "Sample_arrival_Date_to_WECHQ",
      Sample_Return_Date: "Sample_Return_Date",
      SAP_Complete_Date: "SAP_Complete_Date",
      Ship_Out_Date: "Ship_Out_Date",
      status: "status",
      TAT_Case_Close: "TAT_Case_Close",
      TAT_Case_Close_Final_Report_Close: "TAT_Case_Close_Final_Report_Close",
      TAT_Final: "TAT_Final",
      TAT_Initial: "TAT_Initial",
      TEL_Agency: "TEL_Agency",
      TO: "TO",
      Total_Qty_Delivered: "Total_Qty_Delivered",
      type: "type",
      Unique_ID: "Unique_ID",
      Version: "Version",
      WECJ_FAE_Check: "WECJ_FAE_Check",
      WECJ_FAE_Name: "WECJ_FAE_Name",
      yobi: "yobi"
      },
      {
        Action_Requested_Credit: "Action_Requested_Credit",
        Action_Requested_Further_Analysis_Report: "Action_Requested_Further_Analysis_Report",
        Action_Requested_Others: "Action_Requested_Others",
        Action_Requested_Pass_Fail: "Action_Requested_Pass_Fail",
        Action_Requested_Replacement: "Action_Requested_Replacement",
        Application_Category: "Application_Category",
        Application_Category_1: "Application_Category_1",
        Application_Category_2: "Application_Category_2",
        Application_Code: "Application_Code",
        Application_Remark: "Application_Remark",
        Ass_y: "Ass_y",
        CANCEL: "CANCEL",
        CFAR: "CFAR",
        CIP1_Process: "CIP1_Process",
        CIP2_Test: "CIP2_Test",
        CIP3_Test_Vector: "CIP3_Test_Vector",
        CIP4_Process_CIP_Clean_D_C: "CIP4_Process_CIP_Clean_D_C",
        CIP5_Test_CIP_Clean_D_C: "CIP5_Test_CIP_Clean_D_C",
        Close_Date_Monitor: "Close_Date_Monitor",
        Closed_Date: "Closed_Date",
        Closed_Month: "Closed_Month",
        Complaint_Symptom: "Complaint_Symptom",
        create_time: "20200619163601002",
        Cross_Check_Done: "Cross_Check_Done",
        Customer: "Customer",
        Customer_Defect_Rate: "Customer_Defect_Rate",
        Customer_Group: "Customer_Group",
        Customer_ID: "Customer_ID",
        Customer_Request: "Customer_Request",
        Customers_Report_Included: "Customers_Report_Included",
        D_C: "D_C",
        Days_from_Ship_out_to_Fail_Occurrence: "Days_from_Ship_out_to_Fail_Occurrence",
        Density: "Density",
        Distributer: "Distributer",
        EFA1_Programmer_Vt: "EFA1_Programmer_Vt",
        EFA2_ATE_MOSAID_1: "EFA2_ATE_MOSAID_1",
        EFA3_ATE_MOSAID_2: "EFA3_ATE_MOSAID_2",
        EFA3_ATE_MOSAID_2_Details: "EFA3_ATE_MOSAID_2_Details",
        EFA4_Electrical_Failure_Mode: "EFA4_Electrical_Failure_Mode",
        EFA5_Layout_Tracing_Layer_1: "EFA5_Layout_Tracing_Layer_1",
        EFA6_Layout_Tracing_Layer_2: "EFA6_Layout_Tracing_Layer_2",
        FA_Results: "FA_Results",
        Failed_Location: "Failed_Location",
        Failed_Location_2: "Failed_Location_2",
        FAX_Agency: "FAX_Agency",
        Final_Report_Issued: "Final_Report_Issued",
        Final_Report_Issued_Link: "Final_Report_Issued_Link",
        Final_Report_Issued_to_Customer: "Final_Report_Issued_to_Customer",
        FM: "FM",
        FT: "FT",
        Initial_Report_Issued: "Initial_Report_Issued",
        Initial_Report_Issued_Link: "Initial_Report_Issued_Link",
        Initial_Report_Issued_to_Customer: "Initial_Report_Issued_to_Customer",
        Issued_Date: "Issued_Date",
        JQ_PIC: "JQ_PIC",
        Lot_No: "Lot_No",
        Memory_Type: "Memory_Type",
        mode: "mode",
        Module_Name: "Module_Name",
        Month: "Month",
        No: "No",
        of_Bad_Parts: "of_Bad_Parts",
        of_Parts_Used: "of_Parts_Used",
        Other_Report_10_Category: "Other_Report_10_Category",
        Other_Report_10_Date: "Other_Report_10_Date",
        Other_Report_10_Link: "Other_Report_10_Link",
        Other_Report_10_Remark: "Other_Report_10_Remark",
        Other_Report_11_Category: "Other_Report_11_Category",
        Other_Report_11_Date: "Other_Report_11_Date",
        Other_Report_11_Link: "Other_Report_11_Link",
        Other_Report_11_Remark: "Other_Report_11_Remark",
        Other_Report_12_Category: "Other_Report_12_Category",
        Other_Report_12_Date: "Other_Report_12_Date",
        Other_Report_12_Link: "Other_Report_12_Link",
        Other_Report_12_Remark: "Other_Report_12_Remark",
        Other_Report_13_Category: "Other_Report_13_Category",
        Other_Report_13_Date: "Other_Report_13_Date",
        Other_Report_13_Link: "Other_Report_13_Link",
        Other_Report_13_Remark: "Other_Report_13_Remark",
        Other_Report_14_Category: "Other_Report_14_Category",
        Other_Report_14_Date: "Other_Report_14_Date",
        Other_Report_14_Link: "Other_Report_14_Link",
        Other_Report_14_Remark: "Other_Report_14_Remark",
        Other_Report_15_Category: "Other_Report_15_Category",
        Other_Report_15_Date: "Other_Report_15_Date",
        Other_Report_15_Link: "Other_Report_15_Link",
        Other_Report_15_Remark: "Other_Report_15_Remark",
        Other_Report_16_Category: "Other_Report_16_Category",
        Other_Report_16_Date: "Other_Report_16_Date",
        Other_Report_16_Link: "Other_Report_16_Link",
        Other_Report_16_Remark: "Other_Report_16_Remark",
        Other_Report_17_Category: "Other_Report_17_Category",
        Other_Report_17_Date: "Other_Report_17_Date",
        Other_Report_17_Link: "Other_Report_17_Link",
        Other_Report_17_Remark: "Other_Report_17_Remark",
        Other_Report_18_Category: "Other_Report_18_Category",
        Other_Report_18_Date: "Other_Report_18_Date",
        Other_Report_18_Link: "Other_Report_18_Link",
        Other_Report_18_Remark: "Other_Report_18_Remark",
        Other_Report_19_Category: "Other_Report_19_Category",
        Other_Report_19_Date: "Other_Report_19_Date",
        Other_Report_19_Link: "Other_Report_19_Link",
        Other_Report_19_Remark: "Other_Report_19_Remark",
        Other_Report_1_Category: "Other_Report_1_Category",
        Other_Report_1_Date: "Other_Report_1_Date",
        Other_Report_1_Link: "Other_Report_1_Link",
        Other_Report_1_Remark: "Other_Report_1_Remark",
        Other_Report_20_Category: "Other_Report_20_Category",
        Other_Report_20_Date: "Other_Report_20_Date",
        Other_Report_20_Link: "Other_Report_20_Link",
        Other_Report_20_Remark: "Other_Report_20_Remark",
        Other_Report_2_Category: "Other_Report_2_Category",
        Other_Report_2_Date: "Other_Report_2_Date",
        Other_Report_2_Link: "Other_Report_2_Link",
        Other_Report_2_Remark: "Other_Report_2_Remark",
        Other_Report_3_Category: "Other_Report_3_Category",
        Other_Report_3_Date: "Other_Report_3_Date",
        Other_Report_3_Link: "Other_Report_3_Link",
        Other_Report_3_Remark: "Other_Report_3_Remark",
        Other_Report_4_Category: "Other_Report_4_Category",
        Other_Report_4_Date: "Other_Report_4_Date",
        Other_Report_4_Link: "Other_Report_4_Link",
        Other_Report_4_Remark: "Other_Report_4_Remark",
        Other_Report_5_Category: "Other_Report_5_Category",
        Other_Report_5_Date: "Other_Report_5_Date",
        Other_Report_5_Link: "Other_Report_5_Link",
        Other_Report_5_Remark: "Other_Report_5_Remark",
        Other_Report_6_Category: "Other_Report_6_Category",
        Other_Report_6_Date: "Other_Report_6_Date",
        Other_Report_6_Link: "Other_Report_6_Link",
        Other_Report_6_Remark: "Other_Report_6_Remark",
        Other_Report_7_Category: "Other_Report_7_Category",
        Other_Report_7_Date: "Other_Report_7_Date",
        Other_Report_7_Link: "Other_Report_7_Link",
        Other_Report_7_Remark: "Other_Report_7_Remark",
        Other_Report_8_Category: "Other_Report_8_Category",
        Other_Report_8_Date: "Other_Report_8_Date",
        Other_Report_8_Link: "Other_Report_8_Link",
        Other_Report_8_Remark: "Other_Report_8_Remark",
        Other_Report_9_Category: "Other_Report_9_Category",
        Other_Report_9_Date: "Other_Report_9_Date",
        Other_Report_9_Link: "Other_Report_9_Link",
        Other_Report_9_Remark: "Other_Report_9_Remark",
        P_O_No: "P_O_No",
        Part_Number: "Part_Number",
        partition_key: "CFAR",
        PFA: "PFA",
        PFA1_Root_Cause_Defect: "PFA1_Root_Cause_Defect",
        Process: "Process",
        Product_Type: "Product_Type",
        Project_No: "Project_No",
        QA_Receiving_Date_Time: "QA_Receiving_Date_Time",
        QtyReturned_Packing_VI_Case_Count: "QtyReturned_Packing_VI_Case_Count",
        Received_by_QA: "Received_by_QA",
        Request_Date: "Request_Date",
        Request_No: "Request_No",
        RESULT: "RESULT",
        Sales_Manager_WECJ: "Sales_Manager_WECJ",
        sample: "sample",
        Sample_arrival_Date_to_WECHQ: "Sample_arrival_Date_to_WECHQ",
        Sample_Return_Date: "Sample_Return_Date",
        SAP_Complete_Date: "SAP_Complete_Date",
        Ship_Out_Date: "Ship_Out_Date",
        status: "status",
        TAT_Case_Close: "TAT_Case_Close",
        TAT_Case_Close_Final_Report_Close: "TAT_Case_Close_Final_Report_Close",
        TAT_Final: "TAT_Final",
        TAT_Initial: "TAT_Initial",
        TEL_Agency: "TEL_Agency",
        TO: "TO",
        Total_Qty_Delivered: "Total_Qty_Delivered",
        type: "type",
        Unique_ID: "Unique_ID",
        Version: "Version",
        WECJ_FAE_Check: "WECJ_FAE_Check",
        WECJ_FAE_Name: "WECJ_FAE_Name",
        yobi: "yobi"
        }


];


export default HandsonsData_OrderCFAR

