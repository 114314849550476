const HandsonsData_Latest = {}

HandsonsData_Latest.defaultState = {
  activeTab: '1',

  stateModal: false,
  messege: 'sample messege',
  messege2: '',
  messege3: '',
  messege4: '',
  messege5: '',

  write_disabled: true,
  delete_disabled: true,
  Button_hidden: false,
  New_hidden: true,
  Details_hidden: true,
  Admin_hidden: true,

  Btn_hidden_Regist: true,
  Btn_hidden_Fix: true,
  Btn_hidden_Cansel: true,
  Btn_hidden_EditSave: true,
  Btn_hidden_Remand: true,
  Btn_hidden_Approval: true,
}




HandsonsData_Latest.colHeaders_Name = [
  // '削除',
  'ID',
  'Target_Name',
  'Time',
  'Value',
  // 'type',
  // 'status',
  // 'Request_No',
  // 'Project_No',
  // 'yobi' 
];



HandsonsData_Latest.columns_Data = [
  // {
  //   data: 'delete_Flag',
  //   type: "checkbox",
  //   className:'htCenter',
  //   checkedTemplate: "1",
  //   uncheckedTemplate: "0"
  // },
  { data: 'create_time', readOnly: true, className:'htRight', },
  { data: 'Target_Name' },
  { data: 'Latest_Time', className:'htRight', },
  { data: 'Target_Value', className:'htRight', },
  // {data: 'mode'},
  // {data: 'type'},
  // {data: 'status'},
  // {data: 'Request_No'},
  // {data: 'Project_No'},
  // {data: 'yobi'}
];


HandsonsData_Latest.data = [
];

HandsonsData_Latest.sampledata = [
  {
    delete_Flag: "0",
    partition_key: "Latest",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Latest_Time: "",
    Target_Name: "Customer",
    Target_Value: "",
  },
];


HandsonsData_Latest.testdata = [
  {
    delete_Flag: "0",
    partition_key: "Latest",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Target_Name: "Customer",
    Latest_Time: "",
  },
  {
    delete_Flag: "0",
    partition_key: "Latest",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Target_Name: "Customer",
    Latest_Time: "",
  },
  {
    delete_Flag: "0",
    partition_key: "Latest",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Target_Name: "Customer",
    Latest_Time: "",
  },
  {
    delete_Flag: "0",
    partition_key: "Latest",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Target_Name: "Customer",
    Latest_Time: "",
  },
  {
    delete_Flag: "0",
    partition_key: "Latest",
    create_time: '',
    mode: "",
    type: "",
    status: "",
    Target_Name: "Customer",
    Latest_Time: "",
  },


];


export default HandsonsData_Latest
