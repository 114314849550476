const HandsonsData_Login = {}

HandsonsData_Login.testdata = [
  {
    "auth_CFAR": "1",
    "auth_Costomer": "1",
    "auth_Document": "1",
    "auth_Logs": "1",
    "auth_OrderCFAR": "1",
    "auth_OrderCFARAll": "1",
    "auth_OrderDocument": "1",
    "auth_OrderDocumentAll": "1",
    "auth_Tools": "1",
    "auth_Users": "1",
    "create_time": "",
    "mode": "mode",
    "partition_key": "User",
    "status": "status",
    "type": "type",
    "User_GroupID": "009",
    "User_GroupName": "Winbond Electronics",
    "User_ID": "aaaaaaa@aaaaaaaaaaa.jp",
    "User_Name": "テスト全権限ユーザー",
    "User_Pass": "pass",
    "yobi": "yobi"
  }
];

export default HandsonsData_Login
