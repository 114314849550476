import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

import AppContext from './views/Contexts';
// import axios from 'axios';
import HandsonsData_Documents from './views/Theme/Document/HandsonsData_Documents';
// import HandsonsData_OrderDocuments from "./views/Theme/orderDocument/HandsonData_Documents"
import HandsonsData_CFAR from './views/Theme/CFAR/HandsonsData_CFAR';
// import HandsonsData_OrderCFAR from "./views/Theme/orderCFAR/HandsonsData_CFAR"
import HandsonsData_Customer from './views/Theme/Customer/HandsonsData_Customer';
import HandsonsData_Users from './views/Theme/Users/HandsonsData_Users';
import HandsonsData_Login from './views/Theme/Users/HandsonsData_Login';
import HandsonsData_Logs from './views/Theme/Logs/HandsonsData_Logs';
import HandsonsData_Latest from './views/Theme/Latest/HandsonsData_Latest';
// import HandsonsData_PartNo_vs from './views/Theme/PartNo_vs/HandsonsData_PartNo_vs';
// import HandsonsData_Info from './views/Theme/Info/HandsonsData_Info';

import HandsonsData_SubconCode from './views/Theme/Info/HandsonsData_SubconCode';
import HandsonsData_AgencyInformation from './views/Theme/Info/HandsonsData_AgencyInformation';
import HandsonsData_WECJSales from './views/Theme/Info/HandsonsData_WECJSales';
import HandsonsData_ApplicationCode from './views/Theme/Info/HandsonsData_ApplicationCode';
import HandsonsData_WinbondHoliday from './views/Theme/Info/HandsonsData_WinbondHoliday';
import HandsonsData_PartNoVsProductNo from './views/Theme/Info/HandsonsData_PartNoVsProductNo';

import HandsonsData_R_ID from './views/Theme/UpFileList/HandsonsData_R_ID';
import HandsonsData_P_ID from './views/Theme/UpFileList/HandsonsData_P_ID';
import HandsonsData_Reflow from './views/Theme/UpFileList/HandsonsData_Reflow';
import HandsonsData_Q_ID from './views/Theme/UpFileList/HandsonsData_Q_ID';
import HandsonsData_Mark_ID from './views/Theme/UpFileList/HandsonsData_Mark_ID';
import HandsonsData_X_ID from './views/Theme/UpFileList/HandsonsData_X_ID';
import HandsonsData_T_ID from './views/Theme/UpFileList/HandsonsData_T_ID';
import HandsonsData_RoHS from './views/Theme/UpFileList/HandsonsData_RoHS';
import HandsonsData_REACH from './views/Theme/UpFileList/HandsonsData_REACH';
import HandsonsData_MCL from './views/Theme/UpFileList/HandsonsData_MCL';
import HandsonsData_W_ID from './views/Theme/UpFileList/HandsonsData_W_ID';
import HandsonsData_Z_ID from './views/Theme/UpFileList/HandsonsData_Z_ID';
import HandsonsData_M_ID from './views/Theme/UpFileList/HandsonsData_M_ID';
import HandsonsData_S_ID from './views/Theme/UpFileList/HandsonsData_S_ID';

const compDatas = {
  HandsonsData_Documents,
  HandsonsData_CFAR,
  HandsonsData_Customer,
  HandsonsData_Users,
  HandsonsData_Login,
  HandsonsData_Logs,
  HandsonsData_Latest,
  // HandsonsData_Info, // 削除予定
  // HandsonsData_PartNo_vs, // 削除予定
  HandsonsData_SubconCode,
  HandsonsData_AgencyInformation,
  HandsonsData_WECJSales,
  HandsonsData_ApplicationCode,
  HandsonsData_WinbondHoliday,
  HandsonsData_PartNoVsProductNo,
  HandsonsData_R_ID,
  HandsonsData_P_ID,
  HandsonsData_Reflow,
  HandsonsData_Q_ID,
  HandsonsData_Mark_ID,
  HandsonsData_X_ID,
  HandsonsData_T_ID,
  HandsonsData_RoHS,
  HandsonsData_REACH,
  HandsonsData_MCL,
  HandsonsData_Z_ID,
  HandsonsData_M_ID,
  HandsonsData_W_ID,
  HandsonsData_S_ID,
};

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const App = () => {
  console.log('------------------------- App.js ---------------------------------');

  //---------------------
  // レンダリング前
  //---------------------
  useEffect(() => {
    //---------------------
    // Latest取得
    //---------------------
    // console.log('App.js 3 useEffect: Start-----, compDatas:', compDatas)
    // const result3 = common_Get_LatestAPI( compDatas )
    // console.log('App.js 3 useEffect: End----- ')
  }, []);

  return (
    <AppContext.Provider value={compDatas}>
      {/* <button onClick={test}>aaaaa</button> */}
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            {/* <Route path="/" name="Home" render={props => <Login {...props} />} /> */}
          </Switch>
        </React.Suspense>
      </HashRouter>
    </AppContext.Provider>
  );
};

export default App;
